import { Typography } from "antd";
import { FC, useEffect, useState } from "react"
import FlagIcons from "./FlagIcons"
import { PluginCarrierRule } from "../../../../../interfaces/plugin.interface";
import { getCurrency } from "./utils";
import { CountryEnum } from "../../../../../interfaces/plugin.interface";

const styles = {
    container: {
        width: '100%',
        border: '1px solid #E8E8E8',
        borderLeft: '1px solid #E8E8E8',
        borderRadius: '12px',
        padding: '20px',
        paddingBottom: '20px',
        boxShadow: '0 0 0',
        fontSize: "0.9rem",
        marginBottom: '12px'
    }
}

interface props {
    item: any;
    country: CountryEnum
}

const RateTitles: FC<props> = ({ item, country }) => {
    const [userData, setUserData] = useState({ app_country: "MX" })

    useEffect(() => {
        if (country) {
            setUserData({
                app_country: country
            })
        }
    }, [country])

    return (
        <div style={styles.container}>
            {
                item.type === PluginCarrierRule.Weight ? (
                    <Typography>
                        <FlagIcons item={item} userData={userData} />
                        {' '}{item.amount_type === 'percentage' ?
                            "Porcentaje de" :
                            item.amount_type === 'amount' ?
                                "Se le suma la cantidad de" :
                                "Precio fijo de"
                        } <strong>{item.rules.value} {item.amount_type === 'percentage' ? '%' : getCurrency(userData.app_country)}</strong> {"en cotizaciones de"} <strong>{item.rules?.weight}Kg</strong>

                    </Typography>
                ) : item.type === PluginCarrierRule.PostalRange ? (
                    <Typography>
                        <FlagIcons item={item} userData={userData} />
                        {' '}{item.amount_type === 'percentage' ?
                            "Porcentaje de" :
                            item.amount_type === 'amount' ?
                                "Se le suma la cantidad de" :
                                "Precio fijo de"
                        } <strong>{item.rules.value} {item.amount_type === 'percentage' ? '%' : getCurrency(userData.app_country)}</strong> {"en rango desde"} <strong>{item.rules?.postal_range?.first}</strong> {"a"} <strong>{item.rules?.postal_range?.end}</strong>
                    </Typography>
                ) : item.type === PluginCarrierRule.WeightRange ? (
                    <Typography>
                        <FlagIcons item={item} userData={userData} />
                        {' '}{item.amount_type === 'percentage' ?
                            "Porcentaje de" :
                            item.amount_type === 'amount' ?
                                "Se le suma la cantidad de" :
                                "Precio fijo de"
                        } <strong>{item.rules.value} {item.amount_type === 'percentage' ? '%' : getCurrency(userData.app_country)}</strong> {"en rango desde"} <strong>{item.rules?.weight_range?.first}kg</strong> {"a"} <strong>{item.rules?.weight_range?.end}kg</strong>
                    </Typography>
                ) : item.type === PluginCarrierRule.Generic && (
                    <Typography>
                        <FlagIcons item={item} userData={userData} />
                        {' '} {item.amount_type === 'percentage' ?
                            "Porcentaje de"
                            : item.amount_type === 'amount' ?
                                "Se le suma la cantidad de" :
                                "Precio fijo de"
                        } <strong>{item.rules?.value} {item.amount_type === 'percentage' ? '%' : getCurrency(userData.app_country)}</strong> {"en todas las cotizaciones"}

                    </Typography>
                )
            }
        </div>


    )
}

export default RateTitles