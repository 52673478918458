import { FC } from "react"
import CardContainer from "../../../../../components/Containers/CardContainer"
import { Plugin_carrierV2 } from "../../../../../interfaces/plugin.interface"
import { Col, Row } from "antd"
import { assingDashboardLogos } from "../../utils"
import { getServiceName } from "./utils"

const styles = {
    emptyMsgContainer: {
        border: '1px solid #E8E8E8',
        borderLeft: '1px solid #E8E8E8',
        borderRadius: '12px',
        padding: '20px',
        paddingBottom: '20px',
        boxShadow: '0 0 0',
        fontSize: '0.9rem'
    },
    carrierItem: {
        display: 'flex',
        margin: '4px',
        paddingBottom: '12px',
        gap: '2rem',
        border: '1px solid #e1e1e1',
        borderWidth: '0 0 1px 0'
    },
    servicesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '4px',
        gap: '8px 6px'
    },
    serviceItem: {
        padding: '2px 8px',
        backgroundColor: '#f5f5f5',
        border: '1px solid #e1e1e1',
        marginRight: '1rem',
        minWidth: '5rem',
        maxWidth: '20rem'
    }
}

interface props {
    carriers: Plugin_carrierV2[]
}

const CarrierList: FC<props> = ({ carriers }) => {
    return (
        <CardContainer cardStyle={styles.emptyMsgContainer}>
            <Row gutter={[20, 20]}>
                {
                    carriers.map((carrier: Plugin_carrierV2) => (
                        <>
                            <Col span={24} style={styles.carrierItem}>
                                <section>
                                    <img width={42} src={assingDashboardLogos(carrier.name)} alt={carrier.name} />
                                </section>
                                {//@ts-ignore
                                }<section style={styles.servicesContainer}>
                                    {
                                        carrier.services.map(item => (
                                            <span style={styles.serviceItem}>{getServiceName(item)} </span>
                                        ))
                                    }
                                </section>
                            </Col>
                        </>
                    ))}
            </Row>

        </CardContainer>
    )
}

export default CarrierList