import { Col, Row, Tag } from "antd"
import Table, { ColumnsType } from "antd/lib/table"
import { FC, useEffect } from "react"
import InputContainer from "../../../../components/Containers/InputContainer"
import ModalContainer from "../../../../components/Containers/ModalContainer"
import { FileI, OverweightI } from "../Overweight"

interface Props {
    openModal: boolean;
    setOpenModal: (state: boolean) => void
    files: FileI[];
    loading: boolean;
    overweights: OverweightI[];
    discountOverweights: (files: OverweightI[]) => void;
    loadingDiscounts: boolean;
}

const columnsOverweight: ColumnsType<FileI> = [
    {
        title: 'Envío',
        dataIndex: 'tracking',
        key: 'tracking',
        render: (text) => <span>{text}</span>,
    },
    {
        title: 'Monto',
        dataIndex: 'amount',
        key: 'amount',
    },
    {
        title: 'Peso',
        dataIndex: 'weight',
        key: 'weight',
    },
    {
        title: 'Descripción',
        dataIndex: 'description',
        key: 'description',
        render: (text) => <span>{!text ? "N/A" : text}</span>,
    },
]

const columnsCompleteOverweight: ColumnsType<OverweightI> = [
    {
        title: 'Correo',
        dataIndex: 'email',
        key: 'email',
        render: (text) => <span>{text}</span>,
    },
    {
        title: 'Peso',
        dataIndex: 'overweight',
        key: 'overweight',
    },
    {
        title: 'Cobrado',
        dataIndex: 'amount',
        key: 'amount',
        render: (text) => <Tag color='geekblue'>
            {text} MXN
        </Tag>

    },
    {
        title: 'Rastreo',
        dataIndex: 'tracking',
        key: 'tracking',

    },
    {
        title: 'Descripción',
        dataIndex: 'description',
        key: 'description',

    },
]


const ModalFiles: FC<Props> = ({ openModal, setOpenModal, files, loading, overweights, discountOverweights, loadingDiscounts }) => {
useEffect(() =>{
    console.log(loading, "carga")
},[loading])
    return (
        <ModalContainer
            visible={openModal}
            title="Sobrepesos"
            footer={[<></>]}
            onOk={() => setOpenModal(false)}
            onCancel={() => setOpenModal(false)}
        >
            <Row>
                <Col span={24}>
                    {
                        overweights && overweights?.length > 0 ? (
                            <Table
                                columns={columnsCompleteOverweight}
                                dataSource={overweights}
                                loading={loading || loadingDiscounts}
                                pagination={{ pageSize: 5 }}
                            />
                        ) : (
                            <Table
                                columns={columnsOverweight}
                                dataSource={files}
                                loading={loading}
                                pagination={{ pageSize: 5 }}
                            />
                        )
                    }

                </Col>
                <br />
                <Col span={24}>
                    <InputContainer
                        style={{ marginTop: "20px" }}
                        type="button"
                        onClick={() => discountOverweights(overweights)}
                        disabled={loading}
                        title="Cobrar Sobrepesos"
                    />
                </Col>
            </Row>

        </ModalContainer>
    )
}

export default ModalFiles