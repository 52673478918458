import { Col, Row, Collapse } from "antd";
import React from "react";

import Fedex from "../../../../assets/images/ParcelDashboard/fedex.webp";
import Ampm from "../../../../assets/images/ParcelDashboard/ampm.webp";
import Borzo from "../../../../assets/images/ParcelDashboard/borzo.png";
import Estafeta from "../../../../assets/images/ParcelDashboard/estafeta.webp";
import Dhl from "../../../../assets/images/ParcelDashboard/dhl.webp";
import Ups from "../../../../assets/images/ParcelDashboard/ups.webp";
import Carssa from "../../../../assets/images/ParcelDashboard/carssa.png";
import Tresguerras from "../../../../assets/images/ParcelDashboard/tresguerras.png";
import Coordinadora from "../../../../assets/images/ParcelDashboard/coordinadora.png";
import Deprisa from "../../../../assets/images/ParcelDashboard/deprisa.png";
import Interrapidisimo from "../../../../assets/images/ParcelDashboard/interrapidisimo.png";
import Jtexpress from "../../../../assets/images/ParcelDashboard/jtexpress.webp";
import Noventa9minutos from "../../../../assets/images/ParcelDashboard/noventa9minutos.webp";
import MensajerosUrbanos from "../../../../assets/images/ParcelDashboard/mensajerosUrbanos.png";
import Paquetexpress from "../../../../assets/images/ParcelDashboard/paquetexpress.webp";
import Pickit from "../../../../assets/images/ParcelDashboard/pickit.png";
import Ivoy from "../../../../assets/images/ParcelDashboard/ivoy.png";
import Quiken from "../../../../assets/images/ParcelDashboard/quiken.webp";
import Scm from "../../../../assets/images/ParcelDashboard/scm.png";
import Servientrega from "../../../../assets/images/ParcelDashboard/servientrega.png";
import Treggo from "../../../../assets/images/ParcelDashboard/treggo.png";
import Tracusa from "../../../../assets/images/ParcelDashboard/tracusa.png";
import Uber from "../../../../assets/images/ParcelDashboard/uber.png";
import Entrega from "../../../../assets/images/ParcelDashboard/entrega.png";
import Moova from "../../../../assets/images/ParcelDashboard/moova.png";
import TestDrenvio from "../../../../assets/images/ParcelDashboard/testDrenvio.png";
import Sendex from "../../../../assets/images/ParcelDashboard/sendex.webp";
import tcc from "../../../../assets/images/ParcelDashboard/tcc.webp";
import enviaco from "../../../../assets/images/ParcelDashboard/enviaco.webp";
import imile from "../../../../assets/images/ParcelDashboard/imile.webp";
import vencedor from "../../../../assets/images/ParcelDashboard/vencedor.webp";
import redservi from "../../../../assets/images/ParcelDashboard/redServi.webp";

import CardContainer from "../../../../components/Containers/CardContainer";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { t } from "i18next";

type Props = {
  filteredData: any;
  isLoading: boolean;
};

const Panel = Collapse.Panel;

const getImageByName = (carrier: string) => {
  switch (carrier.toLowerCase()) {
    case "fedex":
      return Fedex;
    case "ampm":
      return Ampm;
    case "borzo":
      return Borzo;
    case "estafeta":
      return Estafeta;
    case "dhl":
      return Dhl;
    case "ups":
      return Ups;
    case "carssa":
      return Carssa;
    case "tresguerras":
      return Tresguerras;
    case "coordinadora":
      return Coordinadora;
    case "deprisa":
      return Deprisa;
    case "interrapidisimo":
      return Interrapidisimo;
    case "jtexpress":
      return Jtexpress;
    case "noventa9minutos":
      return Noventa9minutos;
    case "mensajerosurbanos":
      return MensajerosUrbanos;
    case "paquetexpress":
      return Paquetexpress;
    case "pickit":
      return Pickit;
    case "ivoy":
      return Ivoy;
    case "quiken":
      return Quiken;
    case "scm":
      return Scm;
    case "servientrega":
      return Servientrega;
    case "treggo":
      return Treggo;
    case "tracusa":
      return Tracusa;
    case "uber":
      return Uber;
    case "entrega":
      return Entrega;
    case "moova":
      return Moova;
    case "sendex":
      return Sendex;
    case "tcc":
      return tcc;
    case "enviaco":
      return enviaco;
    case "imile":
      return imile;
    case "vencedor":
      return vencedor;
    case "redservi":
      return redservi;
    default:
      return null; // Devuelve null si no se encuentra la imagen
  }
};

const getProviderName = (code: string) => {
  switch (code) {
    // case "D-C01":
    case "sendex-directo-drevsto":
      return "Drevsto"
    case "directo-drenvio":
    case "directo_drenvio":
    case "directo-drevsto":
    case "ups":
      return "Drenvio"
    case "directo-web360":
      return "Web360"
    case "nextshipping":
      return "NS"
    case "globalpaq":
      return "GB"
    case "shippo":
      return "SH"
    case "shipango":
      return "SAG"
    case "t1envios-web360":
      return "T1 Web360"
    case "t1envios-drevsto":
      return "T1 Drevsto"
    case "quiken":
      return "Quiken"
    case "pack2go-web360":
      return "P2G Web360"
    case "pack2go-drevsto":
    case "pak2go-drevsto":
      return "P2G Drevsto"
    case "pak2go-drenvio":
      return "P2G Drenvio"
    case "envia":
      return "Envia"
    case "pakke":
      return "Pakke"
    case "ziyego":
      return "Ziyego"
    case "autopaquete":
    case "autopaquetes":
      return "AP"
    default:
      break;
  }
};

export const ShipmentsToday = ({ filteredData, isLoading }: Props) => {
  return (
    <CardContainer title="Proveedores más usados Hoy" titleStyle={{ placeItems: 'center' }} cardStyle={{ marginTop: "20px" }}>
      <hr />
      {
        filteredData && filteredData.length > 0 && (
          <Row>
            <Col span={15} style={{ margin: "auto", fontWeight: "bold" }}>
              <p>Paqueteria</p>
            </Col>
            <Col span={9} style={{ margin: "auto", fontWeight: "bold", textAlign: "center" }}>
              <p>Total</p>
            </Col>
          </Row>
        )
      }
      <Row style={{ maxHeight: "40vh", overflowY: "auto" }}>
        {isLoading ? (
          <LoadingAnimation animationType="small" />
        ) : !filteredData || filteredData.length === 0 ? (
          <p
            style={{
              fontWeight: "bold",
              margin: "50px auto",
              color: "#0008",
            }}
          >
            No hay datos para mostrar.
          </p>
        ) : (
          filteredData
            .sort((a: any, b: any) => b?.count - a?.count)
            .map((item: any) => {
              return (
                <>
                  <Col
                    span={15}
                    style={{
                      display: "flex",
                    }}
                  >
                    <img
                      width={40}
                      height={40}
                      alt={item.carrier}
                      loading="lazy"
                      src={getImageByName(item.carrier) ?? TestDrenvio}
                    />
                    <Collapse
                      bordered={false}
                      defaultActiveKey={["1"]}
                      style={{ background: "none" }}
                      accordion={true}
                      ghost={true}
                    >
                      <Panel header={<div>{t(`Quote.Service.Carrier.${(item.carrier)}`)}</div>} key={item._id}>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p style={{ margin: 0, fontWeight: "bolder" }}>
                            Proveedor
                          </p>
                          <p style={{ margin: 0, fontWeight: "bolder", textAlign: "center", paddingLeft: 10 }}>
                            Cantidad
                          </p>
                          {item.suppliers.sort((a: any, b: any) => b?.count - a?.count)
                          .map((supplier: any) => {    
                            if(getProviderName(supplier.supplier) === 'Pakke' || getProviderName(supplier.supplier) === 'Envia') return null;                         
                            return (
                            <React.Fragment key={getProviderName(supplier.supplier)}>
                              <div style={{ display: "contents" }}>
                                <div style={{ borderBottom: "1px solid #ccc" }}>
                                  <p style={{ margin: 0 }}>
                                    {getProviderName(supplier.supplier)}
                                  </p>
                                </div>
                                <div style={{
                                  borderBottom: "1px solid #ccc",
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}>
                                  <p style={{ margin: 0 }}>
                                    {supplier.count.toLocaleString()}
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                          )})}
                        </div>
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col span={9} style={{ color: "#2D1B6E", textAlign: "center", fontWeight: "bold", paddingTop: 10 }} >{item.count}</Col>
                </>
              );
            })
        )}
      </Row>
    </CardContainer>
  );
};