import { Col, Row, Select } from 'antd';
import { Fragment, useState, useEffect } from 'react';
import { DashboardAnalyticsRes } from '../../../../interfaces/admin.interface';
import CardContainer from '../../../../components/Containers/CardContainer';
import LoadingAnimation from '../../../../components/LoadingAnimation';
import { Tooltip } from "antd";
import { ServicesId_MX } from '../../../../utils/GlobalServices';

type Props = {
  filteredData: DashboardAnalyticsRes
  isLoading: boolean
}

const providersList: { [key: string]: string } = {
  GP: "A-P09",
  ENV: "A-P01",
  SHPG: "A-P04",
  P2G: "A-P03",
  SH: "A-P02",
  SHWEB360: "A-P06",
  NS: "A-P07",
  T1: "A-P10",
  ZG: "A-P11",
  AP: "A-P12",
  DRENVIO: "D-C01",
  DREVSTO: "D-C02",
  WEB360: "D-C03",
};

const extractProvidersAndServices = (carrier: string) => {
  const services = Object.values(ServicesId_MX).filter((service) =>
    service.includes(carrier)
  );
  const providers = new Set<string>();

  services.forEach((service) => {
    Object.entries(providersList).forEach(([provider, label]) => {
      if (service.includes(label)) {
        providers.add(provider);
      }
    });
  });

  return { services, providers: Array.from(providers) };
};

export const ShipmentsUserTopDaySup = ({ filteredData, isLoading }: Props) => {
  const [selectedCarrier, setSelectedCarrier] = useState('General');
  const [carriers, setCarriers] = useState<string[]>([]);
  const [topUsersByCarrier, setTopUsersByCarrier] = useState<{ [key: string]: any[] }>({});
  const { Option } = Select;

  useEffect(() => {
    const uniqueCarriers = new Set<string>();
    const topUsersMap: { [key: string]: any[] } = {};

    filteredData?.UserTopShipmentsDaySup?.forEach((item: any) => {
      if (item.serviceCode) {
        uniqueCarriers.add(item.serviceCode);
        if (item.topUsers) {
          topUsersMap[item.serviceCode] = item.topUsers;
        }
      }
    });

    setCarriers(Array.from(uniqueCarriers));
    setTopUsersByCarrier(topUsersMap);
  }, [filteredData]);

  return (
    <CardContainer cardStyle={{ marginTop: '20px' }} title='Usuarios con más envíos Hoy por Proveedor' titleStyle={{ placeItems: 'center' }}
    >
      <Row justify="center" style={{ marginBottom: '16px' }}>
        <Col span={24}>
          <Select
            value={selectedCarrier}
            onChange={setSelectedCarrier}
            style={{ width: '100%', textAlign: 'center' }}
          >
            <Option value="General" style={{ textAlign: 'center' }}>
              General
            </Option>
            {carriers.map((carrier) => (
              <Option key={carrier} value={carrier} style={{ textAlign: 'center' }}>
                {extractProvidersAndServices(carrier).providers[0]}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <hr />
      <div>
        <Row>
          <Col span={20} style={{ margin: 'auto', fontWeight: 'bold' }}>
            <p>Usuario</p>
          </Col>
          <Col span={4} style={{ margin: 'auto', fontWeight: 'bold', textAlign: "center" }}>
            <p>Envios</p>
          </Col>
        </Row>
        <Row
          style={{ maxHeight: "36vh", overflowY: 'auto' }}
        >
          {isLoading ? (
            <LoadingAnimation animationType='small' />
          ) : filteredData?.UserTopShipmentsDaySup === undefined ||
            filteredData?.UserTopShipmentsDaySup?.length === 0 ? (
            <p
              style={{
                fontWeight: 'bold',
                margin: '50px auto',
                color: '#0008',
              }}
            >
              No hay datos para mostrar.
            </p>
          ) : (
            (selectedCarrier === "General"
              ? (filteredData.UserTopShipmentsDaySup || [])
              : (topUsersByCarrier[selectedCarrier] || []))
              .filter(item => item?.userEmail && item?.total)
              .sort((a: any, b: any) => {
                const aCount = selectedCarrier === "General"
                  ? a?.total
                  : a?.total || 0;
                const bCount = selectedCarrier === "General"
                  ? b?.total
                  : b?.total || 0;
                return bCount - aCount;
              }).map((item: any, index: number) => {
                const displayValue =
                  selectedCarrier === "General"
                    ? item?.total
                    : item?.total || "";
                return (
                  <Fragment key={index}>
                    {selectedCarrier === "General" || (selectedCarrier !== "General" && topUsersByCarrier[selectedCarrier]?.some((user: any) => user.userEmail === item.userEmail)) ? (
                      <>
                        <Col span={20} style={{ margin: "5px auto" }}>
                          <Tooltip title={item?.total}>{item?.userEmail}</Tooltip>
                        </Col>
                        <Col
                          span={4}
                          style={{ margin: "5px auto", fontWeight: "bold", textAlign: 'center', color: "#2D1B6E" }}
                        >
                          {displayValue}
                        </Col>
                      </>
                    ) : null}
                  </Fragment>
                );
              })
          )}
        </Row>
      </div>
    </CardContainer>
  )
}
