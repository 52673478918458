import moment from 'moment'
import * as XLSX from 'xlsx'
import Title from 'antd/lib/typography/Title'
import { FC, useEffect, useState } from 'react'
import { Button, Table, Input, notification } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { ProfileOutlined } from '@ant-design/icons'
import { AllColumnsType } from '../../interfaces/partner.interface'
import { useToggle } from '../../hooks/useToggle'
import ModalFiltersContainer from '../Containers/ModalFiltersContainer'
import { ModalDataPicker } from '../../pages/DashboardSections/Data/Partners/ModalDataPicker'
import TabsFilterContainer from '../Containers/TabsFilterContainer'
import AddReferralModal from '../../pages/DashboardSections/Data/Partners/AddReferralModal'
import { filterByColumnsContructor, GetAllFilteredQuery } from '../../interfaces/table.interface'
// import { filterByColumnsContructor } from '../../pages/DashboardSections/Records/ShipmentsV2'

type FileType = 'xlsx' | 'csv'

export type TableType =
  // sections 
  | 'shipments'
  | 'cancellations'
  | 'movements'
  | 'pickups'
  | 'apiShipments'

  // referrals 
  | 'profiles'
  | 'referrals'
  | 'withdrawals'
  | 'commissions'
  | 'pending withdrawal by platform'

  //sells
  | 'sellerTracking'

  // fulfilmment
  | 'fulfillProfiles'

  // marketing
  | 'tracking'

  // register
  | 'tokenUsers'

interface TableProps {
  title?: string
  isShipments?: boolean
  isLoading?: boolean
  tableType: TableType
  data: object[];
  total: number; 
  noFormattedDate?: boolean;
  columns: ColumnsType<AllColumnsType> | any
  schemaDownload: (data: any[], noFormattedDate?: boolean) => any[] 
  onChangePage: (page: number) => void
  onChangeRowsPerPage: (row: number) => void
  optionalContent?: {search?: JSX.Element, options? : JSX.Element}
  config?: {
    selection?: any
    disableExport?: boolean
    disableFilters?: boolean
  }
  stateValues: {
    user?: string
    country: CountryAdmin
    currentPage: number
    rowsPerPages: number
    searchText: string
    filterByColumns: GetAllFilteredQuery
    newReferralModal?: boolean
  }
  filters: {
    filterBySearchText: (text: string) => void
    filterByColumns: (filter: GetAllFilteredQuery) => void
    filterDownloads: (filter: GetAllFilteredQuery) => Promise<any>
    handleStateFilterByColumns: (props: filterByColumnsContructor) => GetAllFilteredQuery
    filterByColumnsFactory: (props: filterByColumnsContructor) => GetAllFilteredQuery
    manageReferrals?: {
      activateNewReferral: () => void
      addNewReferral: (newReferral: any) => void 
      toggleNewReferralModal: any
    }
  }
}

export const TableV3: FC<TableProps> = ({
  columns,
  data,
  title,
  config,
  isShipments,
  filters,
  isLoading,
  stateValues,
  onChangePage,
  onChangeRowsPerPage,
  total,
  optionalContent,
  tableType,
  noFormattedDate,
  ...rest
}) => {
  const { t } = useTranslation()
  const { state, toggle } = useToggle()
  const { state: isFiltersOpen, toggle: toggleFiltersModal } = useToggle()
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [fileType, setFileType] = useState<FileType>('xlsx')
  const [rangeDate, setRangeDate] = useState<[string, string] | null>(null)
  const setFilterRange = useState<[Date | null, Date | null]>([null, null])[1]
  const [searchInputText, setSearchInputText] = useState<string>('')
  const [actionSelected, setActionSelected] = useState<string | null>(null)

  const beforeDownload = (fileType: FileType) => {
    setFileType(fileType)
    toggle()
  }

  const downloadExcel = async (type: 'xlsx' | 'csv') => {
    setIsButtonLoading(true)
    try {      
      let formatedData = data 
      if (rangeDate !== null && (rangeDate?.[0] || rangeDate[1])){

        const downloadFilters: filterByColumnsContructor = { 
          isDownload: true, 
          country: stateValues.country, 
          currentPage: 1, 
          rowsPerPages: 10000000000000000 
        }

        if (stateValues?.user){
          downloadFilters.user = stateValues.user ? stateValues.user : undefined
          downloadFilters.isAdmin = stateValues.user ? false : true
        }

        const customFilters = filters.filterByColumnsFactory(downloadFilters)

        customFilters.columns.forEach((column, index ) => {  
          if (column.id === 'createdAt' || column.id === 'created_at'){
            customFilters.columns[index] = {
              id: column.id,
              find: true,
              filters: {
                  sort: false,
                  filter: true,
                  filter_values: [
                    rangeDate?.[0],
                    rangeDate?.[1] 
                  ]
              }
            }
          }
        })

        const updateData = await filters.filterDownloads(customFilters)
        formatedData = rest.schemaDownload(updateData, noFormattedDate)
      } 

      const worksheet = XLSX.utils.json_to_sheet(formatedData as object[])
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, `${title}`)
      XLSX.writeFile(workbook, `${title}.${type}`, { bookType: type })
    } catch (err) {
      console.log({ err })
    } finally {
      setIsButtonLoading(false)
      toggle()
    }
  }

  const handleTableChange = (pagination: { page: number, pageSize: number }) => {
    const { page, pageSize } = pagination

    let tempFilters: GetAllFilteredQuery = stateValues.filterByColumns
    if (stateValues.rowsPerPages !== pageSize){
      tempFilters = filters.handleStateFilterByColumns({ country: stateValues.country, currentPage: 1, rowsPerPages: pageSize })
    } else {
      tempFilters = filters.handleStateFilterByColumns({ country: stateValues.country, currentPage: page })
    }
    filters?.filterByColumns && filters?.filterByColumns(tempFilters)
  }

  const handleFiltersTableTitle = (tableType: TableType) => {
    switch (tableType) {
      // sections 
      case 'shipments':
        return t('Shipments.Table.Title')
      case 'cancellations':
        return t('Cancellations.Table.Title')
      case 'movements':
        return t('Movements.Table.Title')
      case 'pickups':
        return t('Pickups.Table.Title')
      case 'apiShipments':
        return t('Shipments.Table.Title')
      
      // referrals 
      case 'profiles':
        return t('Profiles.Table.Title')
      case 'referrals':
        return t('Referrals.Table.Title')
      case 'withdrawals':
        return t('Withdrawals.Table.Title')
      case 'pending withdrawal by platform':
        return t('Withdrawals.Table.Title')
      
      // fulfillment 
      case 'fulfillProfiles':
        return t('FulfillmentProfiles.Table.Title')

      // marketing 
      case 'tracking':
        return t('Tracking.Table.Title')

      case 'sellerTracking':
        return t('Tracking.Table.Title')

      // register 
      case 'tokenUsers':
        return t('TokenUsers.Table.Title')
    }
  }

  useEffect(() => {
    const tempFilters = filters.handleStateFilterByColumns({ country: stateValues.country, currentPage: 1, searchText: stateValues?.searchText })
    filters?.filterByColumns && filters?.filterByColumns(tempFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateValues?.searchText])

  const { Search } = Input
  
  return (
    <>
      <Table
        size="small"
        loading={isLoading}
        scroll={{ x: true }}
        pagination={{
          current: stateValues.currentPage,
          pageSize: stateValues.rowsPerPages,
          total, 
          pageSizeOptions: ['5', '10', '20', '50', '100', '500'],
          onChange: (page, pageSize) => handleTableChange({ page, pageSize })
        }}
        title={() => (
          <div>
            {title && <Title level={4}>{title}</Title>}
            <div>
              {!config?.disableExport && (
                <div className="containerOptionsTable">
                  <div className="containerbuttons">
                    <div>
                      <Button
                        loading={isButtonLoading}
                        onClick={() => beforeDownload('csv')}
                        style={{ marginRight: '1rem', alignItems: 'center', display: 'flex' }}
                        icon={<ProfileOutlined />}
                      >
                        {t('Table.Options.DownloadCSV')}
                      </Button>
                    </div>
                    <div>
                      <Button
                        loading={isButtonLoading}
                        onClick={() => beforeDownload('xlsx')}
                        style={{ marginRight: '1rem', alignItems: 'center', display: 'flex' }}
                        icon={<ProfileOutlined />}
                      >
                        {t('Table.Options.DownloadXLSX')}
                      </Button>
                    </div>
                  </div>
                  <div className="containerButtonsTwo">
                    {!config?.disableFilters && (
                      <div>
                        <Button
                          style={{ marginRight: '1rem', alignItems: 'center', display: 'flex' }}
                          onClick={() => {
                            setActionSelected('Filter')
                            toggleFiltersModal()
                          }}
                        >
                          {t('Table.Options.Filters')}
                        </Button>
                      </div>
                    )}
                    {optionalContent?.options && (optionalContent?.options)}
                  </div>
                </div>
              )}
            </div>
            <div className='container-search-table'>
              <Search
                className="searchInput"
                placeholder={t('Table.Search.Placeholder')}
                onChange={(e) => {
                  setSearchInputText(e.target.value)
                }}
                onSearch={() => {
                  filters?.filterBySearchText(searchInputText)
                }}
                style={{ 
                  width: 200, 
                  marginTop: '15px', 
                  marginLeft: 'none', 
                  marginRight: '10px'
                }}

              />
              {optionalContent?.search && (optionalContent?.search)}
            </div>
          </div>
        )}
        columns={columns}
        dataSource={data as any[]}
        rowSelection={config?.selection ? config?.selection : undefined}
      />
      <ModalDataPicker
        isLoading={isButtonLoading}
        onAction={() => {
          if (!rangeDate?.[0] || !rangeDate[1]) {
            notification.warning({
              message: t('Table.RangeDateModal.Notification.Warning')
            })
            return
          }
          downloadExcel(fileType)
        }}
        isOpen={state}
        onCancel={toggle}
        setState={(e: any) => {
          setRangeDate([moment(e[0]._d).format('YYYY-MM-DD'), moment(e[1]._d).format('YYYY-MM-DD')])
        }}
      />
      <ModalFiltersContainer
        title={`${t('Table.Filters.MainTitle')} ${handleFiltersTableTitle(tableType)}`}
        visible={isFiltersOpen}
        onCancel={toggleFiltersModal}
        footer={false}
      >
        <TabsFilterContainer
          data={data}
          actionSelected={actionSelected}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          toggleFiltersModal={toggleFiltersModal}
          setFilterRange={setFilterRange}
          stateValues={stateValues}
          setFilterByColumns={filters?.filterByColumns}
          tableType={tableType}
        />
      </ModalFiltersContainer>
      <AddReferralModal 
        newReferralModal={stateValues?.newReferralModal ?? false}  
        filters={filters}
      />
    </>
  )
}
