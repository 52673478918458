
export enum CountryEnum {
	MX = 'MX',
	CO = 'CO',
	CA = 'CA',
	US = 'US',
	PE = 'PE',
	pr = 'PR',
}

export interface PersonalAccountI {
	_id?: string;
	title: string;
	name: string;
	type: string;
	active?: boolean;
	created_at?: string;
	metadata: PersonalAccountMetaI;
}

export interface PersonalAccountMetaI {
	fedex?: {
		account_number: string;
		client_id: string;
		secret_key: string;
	};
	estafeta?: {
		customer_id: string;
		customer_number: string;
		api_key: string;
		api_key_secret: string;
		api_pass: string;
		api_key_pickups: string;
		api_key_consignment: string;
		api_secret_consignment: string;
	};
	dhl?: {
		account_number: string;
		api_key: string;
		api_key_secret: string;
	};
	quiken?: {
		account: string;
		key_id: string;
	};
	ampm?: {
		user: string;
		password: string;
		account_number: string;
	};
	sendex?: {
		user: string;
		password: string;
		account_number: string;
	};
	noventa9minutos?: {
		client_id: string;
		client_secret: string;
	};
	redpack?: {
		pin: string;
		id: string;
		username: string;
		password: string;
		moneda?: string;
		client?: string;
	};
	jtexpress?: {
		apiAccount: string;
		privateKey: string;
		apiPassword: string;
		customerCode: string;
	};
	entrega?: {
		username_entrega: string;
		password_entrega: string;
	};
	borzo?: {
		apiKey: string;
	};
	coordinadora?: {
		apiKey: string;
		apiPass: string;
		nit: string;
		clientId: string;
		password: string;
		user: string;
	};
	tresguerras?: {
		user: string;
		password: string;
	};
	treggo?: {
		accountName: string;
		apiKey: string;
	};
	moova?: {
		appId: string;
		secrectKey: string;
	};
	uber?: {
		customerId: string;
		cliendId: string;
		clientSecret: string;
	};
	ups?: {
		clientId: string;
		secretKey: string;
		redirect_uri: string;
		merchantId: string;
		userEmail: string;
		password: string;
	};
}

export interface PluginInterfaceV2 {
    _id?: string;
    user: string;
    type: PluginEnum;
    access_tokens: string;
    address?: PluginAddressV2 | null;
    country: CountryEnum;
    free_shipping: Plugin_free_shippingV2[];
    delivery_in_store: Plugin_free_shippingV2[];
    carriers: {
        national: Plugin_carrierV2[];
        international: Plugin_carrierV2[];
        personal_accounts: Plugin_carrierV2[];
    },
    packages: Plugin_packagesV2[];
    rates_rules: Plugin_free_shippingV2[];
    generate_rules?: Generate_RulesI;
    created_at: string;
    updated_at?: string;
}

export interface PluginAddressV2 {
    address_id: string,
    country: string,
    state: string,
    city: string,
    postal_code: string,
    district: string

}


export enum PluginEnum {
    Wordpress = 'WORDPRESS',
    Shopify = 'SHOPIFY'
}

export enum PluginCarrierRule {
    Percentage = 'percentage',
    Weight = 'weight',
    WeightRange = 'weight_range',
    PostalRange = 'postal_range',
    Price = 'price',
    Amount = 'amount',
    Products_count = 'products_count',
    Generic = 'generic',
}

export enum PluginScopeEnum {
    National = 'National',
    International = 'International',
    Both = 'Both',
}

export interface Plugin_carrierV2 {
    _id?: any,
    name: string;
    services: string[];
    account?: PersonalAccountI;
    account_id?: string,
}

export interface Plugin_free_shippingV2 {
    type: PluginCarrierRule;
    amount_type?: PluginCarrierRule;
    scope: PluginScopeEnum;
    rules: PluginRulesI;
}

export interface Generate_RulesI {
    active: boolean;
    local_shipment_first: boolean;
    free_shipment_generation: boolean;
    default_carriers: Plugin_carrierV2[]
}

interface PluginRulesI {
    percentage?: number;
    price?: number;
    amount?: number;
    weight?: number;
    products_count?: number;
    value?: number;
    postal_range?: {
        first: string;
        end: string;
    }
    weight_range?: {
        first: number;
        end: number;
    }

}

export enum PluginFreeEnum {
    FreeShipping = 'free_shipping',
    DeliveryInStore = 'delivery_in_store'
}

export interface Plugin_packagesV2 {
    _id?: string;
    scope: PluginScopeEnum;
    rules: {
        weight_range: {
            first: string;
            end: string;
        },
        package_id: string;
        dimensions?: any;
    };
}

export interface Plugin_Rate {
    origin?: {
        postal_code: string;
        country: string;
    },
    destination: {
        postal_code: string;
        country: string;
    },
    packages: [
        {
            weight: number;
        }
    ],
    products_count: number;
    subtotal: number;
    type: PluginEnum;
    no_static_token?: boolean;
}