import COIcon from '../../../../../assets/images/Countries/CO.png'
import MXIcon from '../../../../../assets/images/Countries/MX.png'

export const switchCountry = (country: string) => {
    switch (country) {
        case 'CO':
            return COIcon
        default:
            return MXIcon
    }
}

export const getCurrency = (country: string) => {
    return country === 'CO' ? 'cop' : 'mxn'
}

export const getServiceName = (service: string) => {
    switch (service) {
        case 'ground':
            return 'Terrestre'
        case 'express':
            return 'Express'
        case '2_days':
            return '2 días'
        case 'next_day':
            return 'Express'
        case 'metropolitano':
            return 'Local'
        case 'same_day':
            return 'Mismo día'
        case 'domestic_10-30':
            return 'Antes de las 10:30'
        case 'domestic_9-00':
            return 'Antes de las 9:00'
        case 'domestic_12-00':
            return 'Antes de las 12:00'
        case 'domestic_easy_doc':
            return 'Express Easy doc'
        case 'domestic_shipment_departure':
            return 'Domestic shipment departure'
        case 'domestic_medical':
            return 'Express medical'
        case 'ground_without_pickup':
            return 'Terrestre sin recolección'
        case 'next_day_without_pickup':
            return 'Express sin recolección'
        case 'economy':
        case 'economic':
        case 'international':
            return 'Internacional económico'
        case 'priority':
            return 'Internacional prioridad'
        case 'standard':
            return 'Standard'
        case 'plus':
            return 'Plus'
        default:
            return service
    }
}