import { Skeleton } from "antd";

const ServiceSkeleton = () => (
    <article style={{ border: '1px solid #F0F0F0', padding: '1rem', borderRadius: '6px', marginBottom: '12px' }}>
        <Skeleton avatar={{ shape: 'square', size: 50 }} paragraph={{ rows: 1 }} loading={true} active>
            <div style={{ display: 'grid', gridTemplateColumns: '6rem 1fr', gridTemplateRows: '1fr' }}>
                <div>
                    <Skeleton.Avatar active size={50} shape="square" />
                </div>
                <div>
                    <Skeleton active paragraph={{ rows: 1 }} title={false} />
                </div>
            </div>
        </Skeleton>
    </article>
);

export default ServiceSkeleton