import { FC } from 'react'
import { switchCountry } from './utils'
import { Plugin_packagesV2 } from '../../../../../interfaces/plugin.interface'
import WorldIcon from '../../../../../assets/images/CountriesFlags/world.svg'

interface FlagIconsProps {
  item: Plugin_packagesV2
  userData: any
}

const FlagIcons: FC<FlagIconsProps> = ({ item, userData }) => {
  return (
    <>
      {item.scope === 'National' ? (
        <img alt="country icon" src={switchCountry(userData.app_country)} width={16} height={16} />
      ) : item.scope === 'International' ? (
        <img alt="world icon" src={WorldIcon} width={16} height={16} />
      ) : (
        <>
          <img
            alt="country icon"
            src={switchCountry(userData.app_country)}
            width={16}
            height={16}
          />
          <img alt="world icon" src={WorldIcon} width={16} height={16} />
        </>
      )}
    </>
  )
}

export default FlagIcons