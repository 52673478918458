import { Typography } from "antd"
import { PluginCarrierRule } from "../../../../../interfaces/plugin.interface"
import { FC, useEffect, useState } from "react"
import FlagIcons from "./FlagIcons"
import { t } from "i18next"
import { getCurrency } from "./utils"
import { CountryEnum } from "../../../../../interfaces/plugin.interface"

interface props {
    item: any;
    country: CountryEnum
}
const styles = {
    container: {
        width: '100%',
        border: '1px solid #E8E8E8',
        borderLeft: '1px solid #E8E8E8',
        borderRadius: '12px',
        padding: '20px',
        paddingBottom: '20px',
        boxShadow: '0 0 0',
        fontSize: '0.9rem',
        marginBottom: '12px'
    }
}

const FreeShippingTitles: FC<props> = ({ item, country }) => {
        const [userData, setUserData] = useState({app_country: "MX"})
    
        useEffect(() =>{
            if(country){
                setUserData({
                    app_country: country
                })
            }
        },[country])
    return (
        <div style={styles.container}>
            {
                item.type === PluginCarrierRule.Weight ? (
                    <Typography><FlagIcons item={item} userData={userData} />{' '}{"En cotizaciones de"} <strong>{item.rules?.weight}Kg</strong>

                    </Typography>
                ) : item.type === PluginCarrierRule.PostalRange ? (
                    <Typography>
                        <FlagIcons item={item} userData={userData} />{' '}{t('Rango desde')} <strong>{item.rules?.postal_range?.first}</strong> {"a"} <strong>{item.rules?.postal_range?.end}</strong>
                    </Typography>
                ) : item.type === PluginCarrierRule.Price ? (
                    <Typography>
                        <FlagIcons item={item} userData={userData} />{' '}{"A partir de"} <strong>{item.rules?.price} {getCurrency(userData.app_country)}</strong>{' '}

                    </Typography>
                ) : item.type === PluginCarrierRule.Products_count && (
                    <Typography>
                        <FlagIcons item={item} userData={userData} />{' '}{"A partir de"} <strong>{item.rules?.products_count} {"producto"}{item.rules?.products_count && item.rules?.products_count > 1 ? 's' : ''}</strong>
                    </Typography>
                )
            }
        </div>

    )
}

export default FreeShippingTitles