// Generic Imports
import React, { useEffect, useState } from "react";
import TabsContainer from "../../components/Containers/TabsContainer";

// Table Components
import ShipmentsV2 from "./Records/ShipmentsV2";
import CancellationsV2 from "./Records/Cancellations/CancellationsV2";
import RecollectionsV2 from "./Records/Recollections/RecollectionsV2";
import ApiShipmentsV2 from "./Records/ApiShipmentsV2";
import Users from "./Records/Users";
import Reports from "./Records/Reports";
import MovementsV2 from "./Records/MovementsV2";
import PersonalAccounts from "./Records/PersonalAccounts";
import usePermission from "../../hooks/usePermission";
import {
  ActionsSections,
  PermissionViews,
} from "../../interfaces/permissions.interfaces";
import QuotesPerCarrier from "./QuotesPerCarrier";

export interface RecordAccessI {
  shipments: boolean;
  cancelations: boolean;
  users: boolean;
  recolections: boolean;
  movements: boolean;
  reports: boolean;
  balances?: boolean;
  apiShipments?: boolean;
  personalAccounts?: boolean;
  quotesPerCarrier?: boolean;
}

const Home: React.FC = () => {
  const { hasAccess } = usePermission();
  const [tabContent, setTabContent] = useState({});
  const [tabAccess] = useState<RecordAccessI>({
    shipments: hasAccess(ActionsSections.shipments, PermissionViews.sections),
    cancelations: hasAccess(
      ActionsSections.cancelations,
      PermissionViews.sections
    ),
    users: hasAccess(ActionsSections.users, PermissionViews.sections),
    recolections: hasAccess(
      ActionsSections.recolections,
      PermissionViews.sections
    ),
    movements: hasAccess(ActionsSections.movements, PermissionViews.sections),
    reports: hasAccess(ActionsSections.reports, PermissionViews.sections),
    apiShipments: hasAccess(ActionsSections.api_shipments, PermissionViews.sections),
    personalAccounts: hasAccess(ActionsSections.personalAccounts, PermissionViews.sections),
    quotesPerCarrier: hasAccess(ActionsSections.personalAccounts, PermissionViews.sections),
  });

  const assignTabs = () => {
    let tabObject = {};

    if (tabAccess.shipments) {
      tabObject = {
        ...tabObject,
        Envios: {
          component: <ShipmentsV2 />,
          description: "Envíos",
        },
      };
    }

    if (tabAccess.cancelations) {
      tabObject = {
        ...tabObject,
        Cancelaciones: {
          component: <CancellationsV2 />,
          description: "Cancelaciones",
        },
      };
    }

    if (tabAccess.movements) {
      tabObject = {
        ...tabObject,
        Movimientos: {
          component: <MovementsV2 />,
          description: "Movimientos",
        },
      };
    }

    if (tabAccess.recolections) {
      tabObject = {
        ...tabObject,
        Recolecciones: {
          component: <RecollectionsV2 />, 
          description: "Recolecciones",
        },
      };
    }

    if (tabAccess.reports) {
      tabObject = {
        ...tabObject,
        Reportes: {
          component: <Reports />,
          description: "Reportes",
        },
      };
    }

    if (tabAccess.users) {
      tabObject = {
        ...tabObject,
        Usuarios: {
          component: <Users />,
          description: "Usuarios",
        },
      };
    }

    if (tabAccess.apiShipments) {
      tabObject = {
        ...tabObject,
        'Envíos del API': {
          component: <ApiShipmentsV2 />,
          description: "Envíos del API",
        },
      };
    }

    if (tabAccess.personalAccounts) {
      tabObject = {
        ...tabObject,
        'Cuentas Personales': {
          component: <PersonalAccounts />,
          description: "Cuentas Personales",
        },
      };
    }

    if (tabAccess.quotesPerCarrier) {
      tabObject = {
        ...tabObject,
        'Cotizaciones por Paqueteria': {
          component: <QuotesPerCarrier />,
          description: "Cotizaciones por Paqueteria",
        },
      };
    }

    setTabContent(tabObject);
  };

  useEffect(() => {
    assignTabs();
    //eslint-disable-next-line
  }, [tabAccess]);

  return (
    <section className="dashboardSection">
      <TabsContainer titlesAndContent={tabContent} />
    </section>
  );
};

export default Home;
