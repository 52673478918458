
import InputContainer from './InputContainer'
import FilterListIcon from '@material-ui/icons/FilterList'
import Info from '@material-ui/icons/Info'
import { Tabs, Button, Tag } from 'antd'
import { useState, useEffect, Dispatch, SetStateAction, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TableType } from '../Common/TableV3'
import { allParcelsObject } from '../../constants/parcels'
import { AdminDataContext } from '../../context/adminContext'
import { FilterByDate } from '../Common/FilterByDate'
import { GetAllFilteredQuery } from '../../interfaces/table.interface'

const allCarriersLabels = Object.values(allParcelsObject)
  .filter(carrier => carrier.country.includes("MX"))
  .map(carrier => ({
    name: carrier.key,
    label: carrier.commercial_name,
  }))
  
type TypeTag = 
    | 'Date'  
    | 'Carrier' 
    | 'Price' 
    | 'Type' 
    | 'ShipmentStatu' 
    | 'CancellationStatu' 
    | 'PickupStatu' 
    | 'ApiShipmentStatu'
    | 'ReferralStatu' 
    | 'WithdrawalStatu'
    | 'RegMethod'
    | 'AppCountry'
    | 'UserActivity'
    | null 

type Tags = 
    | TagDate 
    | TagParcel
    | TagPrice
    | TagType
    | TagShipmentStatu
    | TagCancellationStatu
    | TagPickupStatu
    | TagApiShipmentStatu
    | TagReferralStatu
    | TagWithdrawalStatu
    | TagRegMethod
    | TagAppCountry
    | TagUserActivity

type TagDate =
    | 'Hoy'
    | 'Ayer'
    | 'Últimos 7 días'
    | 'Últimos 30 días'
    | 'Este año'
    | 'Rango personalizado'
    | undefined

type TagParcel =
    | 'Fedex'
    | 'DHL'
    | 'Estafeta'
    | 'Redpack'
    | 'Carssa'
    | 'Quiken'
    | 'Scm'
    | 'Sendex'
    | 'Ampm'
    | 'Ivoy'
    | 'MensajerosUrbanos'
    | 'Noventa9Minutos'
    | 'Paquetexpress'
    | 'Uber'
    | 'Ups'
    | 'aExpress'
    | 'Coordinadora'
    | 'InterRapidisimo'
    | 'RedServi'
    | 'ServiEntrega'
    | 'Interrapidisimo'
    | 'Jtexpress'
    | 'Borzo'
    | 'Tresguerras'
    | 'Treggo'
    | 'Pickit'
    | 'Vencedor'
    | 'Moova'
    | 'Olva'
    | 'Nirex'
    | 'Envia'
    | 'Picap'
    | 'Imile'
    | 'TCC'
    | 'Envia'
    | 'test'
    | undefined

type TagPrice = 
    | 'Precios altos' 
    | 'Precios bajos' 
    | undefined

type TagType = 
    | 'Envio' 
    | 'Sobrepeso' 
    | 'Descuento' 
    | 'Recarga' 
    | 'Reembolso' 
    | 'Sincronizacion de saldo' 
    | 'Notificacion' 
    | 'Referido' 
    | 'Actualizacion de usuario' 
    | 'Desbloqueo de usuario' 
    | 'Bloqueo de usuario' 
    | undefined

type TagShipmentStatu = 
    | 'Activa' 
    | 'Entregada'
    | undefined

type TagCancellationStatu = 
    | 'Solicitada' 
    | 'C. y usada' 
    | 'Abonada' 
    | 'Cancelada de prov' 
    | undefined

type TagPickupStatu = 
    | 'Agendado' 
    | 'Cancelada de prov' 
    | 'Solicitada' 
    | 'En espera' 
    | 'Sin disponibilidad' 
    | undefined

type TagApiShipmentStatu = 
    | 'Activa' 
    | 'Entregada'
    | 'Solicitada' 
    | 'C. y usada' 
    | 'Abonada' 
    | 'Cancelada de prov' 
    | undefined

type TagReferralStatu = 
    | 'Aprobado' 
    | 'Pendiente' 
    | 'Rechazado' 
    | undefined

type TagWithdrawalStatu = 
    | 'Abonado' 
    | 'Pendiente' 
    | 'Rechazado' 
    | undefined

type TagRegMethod = 
    |'Google'
    |'Facebook'
    |'Auth0'

type TagAppCountry = 
    |'Colombia'
    |'Mexico'
    |'Peru'

type TagUserActivity = 
    |'Nuevos usuarios'
    |'Sin recargar'
    |'Primera recarga'

type Action = 
    | 'Filter' 

interface ActionBody {
    name: Action
    payload: {
        icon: () => React.ReactElement
    }
}

const allActions: ActionBody[] = [
    {
        name: 'Filter',
        payload: {
            icon: () => <FilterListIcon className="applyFiltersIcon" />
        }
    }
]

const tagsColor: string[] = [
    'rgb(22, 145, 167)',
    'rgb(27, 139, 23)',
    'rgb(211, 214, 11)',
    'rgb(101, 52, 167)'
]

interface Props {
    data: object[]
    actionSelected: string | null
    onChangePage: (page: number) => void
    onChangeRowsPerPage: (row: number) => void
    toggleFiltersModal: () => void
    setFilterRange: Dispatch<SetStateAction<[Date | null, Date | null]>>
    tableType?: TableType
    stateValues?: any
    setFilterByColumns?: (filter: GetAllFilteredQuery) => void
}

const TabsFilterContainer = ({
    actionSelected, 
    onChangePage,
    onChangeRowsPerPage,
    stateValues,
    toggleFiltersModal,   
    setFilterByColumns,
    tableType
}: Props)=> {
    const { t } = useTranslation()
      const { country } = useContext(AdminDataContext)
    const [currentAction, setCurrentAction] = useState(allActions.filter(action => action.name === actionSelected))
        
    const [selectedAllFilters, setSelectedAllFilters] = useState<Tags[]>([])
    const [activateTags, setActivateTags] = useState<boolean>(false)
    const [typeTags, setTypeTags] = useState<TypeTag>(null)

    const [range, setRange] = useState<[Date | null, Date | null]>([null, null])
    const [rangeTag, setRangeTag] = useState<TagDate[]>([])
    const [selectedRangeFilters, setSelectedRangeFilters] = useState<TagDate[]>([])

    const [carriers, setCarriers] = useState<string[]>([])
    const [carrierSelected, setCarrierSelected] = useState<TagParcel[]>([])
    const [selectedCarrierFilters, setSelectedCarrierFilters] = useState<TagParcel[]>([])
    const [carriersListOptions, setCarriersListOptions] = useState<string[]>([])
    
    const [prices, setPrices] = useState<string[]>([])
    const [priceSelected, setPriceSelected] = useState<TagPrice[]>([])
    const [selectedPriceFilters, setSelectedPriceFilters] = useState<TagPrice[]>([])

    const [types, setTypes] = useState<string[]>([])
    const [typeSelected, setTypeSelected] = useState<TagType[]>([])
    const [selectedTypeFilters, setSelectedTypeFilters] = useState<TagType[]>([])

    const [shipmentStatus, setShipmentStatus] = useState<string[]>([])
    const [shipmentStatuSelected, setShipmentStatuSelected] = useState<TagShipmentStatu[]>([])
    const [selectedShipmentStatuFilters, setSelectedShipmentStatuFilters] = useState<TagShipmentStatu[]>([])
    
    const [cancellationStatus, setCancellationStatus] = useState<string[]>([])
    const [cancellationStatuSelected, setCancellationStatuSelected] = useState<TagCancellationStatu[]>([])
    const [selectedCancellationStatuFilters, setSelectedCancellationStatuFilters] = useState<TagCancellationStatu[]>([])

    const [pickupStatus, setPickupStatus] = useState<string[]>([])
    const [pickupStatuSelected, setPickupStatuSelected] = useState<TagPickupStatu[]>([])
    const [selectedPickupStatuFilters, setSelectedPickupStatuFilters] = useState<TagPickupStatu[]>([])

    const [apiShipmentStatus, setApiShipmentStatus] = useState<string[]>([])
    const [apiShipmentStatuSelected, setApiShipmentStatuSelected] = useState<TagApiShipmentStatu[]>([])
    const [selectedApiShipmentStatuFilters, setSelectedApiShipmentStatuFilters] = useState<TagApiShipmentStatu[]>([])

    const [referralStatus, setReferralStatus] = useState<string[]>([])
    const [referralStatuSelected, setReferralStatuSelected] = useState<TagReferralStatu[]>([])
    const [selectedReferralStatuFilters, setSelectedReferralStatuFilters] = useState<TagReferralStatu[]>([])

    const [withdrawalStatus, setWithdrawalStatus] = useState<string[]>([])
    const [withdrawalStatuSelected, setWithdrawalStatuSelected] = useState<TagWithdrawalStatu[]>([])
    const [selectedWithdrawalStatuFilters, setSelectedWithdrawalStatuFilters] = useState<TagWithdrawalStatu[]>([])

    const [regMethod, setRegMethod] = useState<string[]>([])
    const [regMethodSelected, setRegMethodSelected] = useState<TagRegMethod[]>([])
    const [selectedRegMethodFilters, setSelectedRegMethodFilters] = useState<TagRegMethod[]>([])

    const [appCountry, setAppCountry] = useState<string[]>([])
    const [appCountrySelected, setAppCountrySelected] = useState<TagAppCountry[]>([])
    const [selectedAppCountryFilters, setSelectedAppCountryFilters] = useState<TagAppCountry[]>([])

    const [userActivity, setUserActivity] = useState<string[]>([])
    const [userActivitySelected, setUserActivitySelected] = useState<TagUserActivity[]>([])
    const [selectedUserActivityFilters, setSelectedUserActivityFilters] = useState<TagUserActivity[]>([])

    const tagsContainerRef = useRef<HTMLDivElement | null>(null)

    const cleanTag = (tag: Tags) => {
        const rangeDateFiltered = selectedRangeFilters.filter((filterTag) => filterTag !== tag)
        setSelectedRangeFilters(rangeDateFiltered)

        const carriersFiltered = selectedCarrierFilters.filter((filterTag) => filterTag !== tag)
        setSelectedCarrierFilters(carriersFiltered)
        
        const pricesFiltered = selectedPriceFilters.filter((filterTag) => filterTag !== tag)
        setSelectedPriceFilters(pricesFiltered)

        const typesFiltered = selectedTypeFilters.filter((filterTag) => filterTag !== tag)
        setSelectedTypeFilters(typesFiltered)

        const shipmentStatusFiltered = selectedShipmentStatuFilters.filter((filterTag) => filterTag !== tag)
        setSelectedShipmentStatuFilters(shipmentStatusFiltered)
        
        const cancellationStatusFiltered = selectedCancellationStatuFilters.filter((filterTag) => filterTag !== tag)
        setSelectedCancellationStatuFilters(cancellationStatusFiltered)

        const pickupStatusFiltered = selectedPickupStatuFilters.filter((filterTag) => filterTag !== tag)
        setSelectedPickupStatuFilters(pickupStatusFiltered)

        const apiShipmentStatusFiltered = selectedApiShipmentStatuFilters.filter((filterTag) => filterTag !== tag)
        setSelectedApiShipmentStatuFilters(apiShipmentStatusFiltered)

        const referralStatusFiltered = selectedReferralStatuFilters.filter((filterTag) => filterTag !== tag)
        setSelectedReferralStatuFilters(referralStatusFiltered)
        
        const regMethodFiltered = selectedRegMethodFilters.filter((filterTag) => filterTag !== tag)
        setSelectedRegMethodFilters(regMethodFiltered)

        const appCountryFiltered = selectedAppCountryFilters.filter((filterTag) => filterTag !== tag)
        setSelectedAppCountryFilters(appCountryFiltered)

        const userActivityFiltered = selectedUserActivityFilters.filter((filterTag) => filterTag !== tag)
        setSelectedUserActivityFilters(userActivityFiltered)

        handleCleanTags('removeOneTag', tag)
    }

    const handleCleanTags = (actionType: 'removeAllTags' | 'removeOneTag', tag?: Tags) => {
        /* eslint-disable no-duplicate-case, no-fallthrough */
        switch(actionType){
            case 'removeAllTags':
                // date 
                setSelectedRangeFilters([])
                setRange([null, null])
                setRangeTag([])

                // carriers 
                setCarriers([])
                setCarrierSelected([])
                setSelectedCarrierFilters([])

                // prices 
                setPrices([])
                setPriceSelected([])
                setSelectedPriceFilters([])

                // types 
                setTypes([])
                setTypeSelected([])
                setSelectedTypeFilters([])

                // shipment statu
                setShipmentStatus([])
                setShipmentStatuSelected([])
                setSelectedShipmentStatuFilters([])
                
                // cancellation statu
                setCancellationStatus([])
                setCancellationStatuSelected([])
                setSelectedCancellationStatuFilters([])

                // pickup statu
                setPickupStatus([])
                setPickupStatuSelected([])
                setSelectedPickupStatuFilters([])

                // api shipment statu
                setApiShipmentStatus([])
                setApiShipmentStatuSelected([])
                setSelectedApiShipmentStatuFilters([])

                // referral statu
                setReferralStatus([])
                setReferralStatuSelected([])
                setSelectedReferralStatuFilters([])

                // withdrawal statu
                // setWithdrawalStatus([])
                // setWithdrawalStatuSelected([])
                // setSelectedWithdrawalStatuFilters([])

                // reg method 
                setRegMethod([])
                setRegMethodSelected([])
                setSelectedRegMethodFilters([])

                // app country 
                setAppCountry([])
                setAppCountrySelected([])
                setSelectedAppCountryFilters([])

                // user activity 
                setUserActivity([])
                setUserActivitySelected([])
                setSelectedUserActivityFilters([])
                break 
            case 'removeOneTag':
                switch(tag){
                    // date 
                    case t('Shipments.Filters.Tabs.Date.DatePicker.Today'):
                    case t('Shipments.Filters.Tabs.Date.DatePicker.Yesterday'):
                    case t('Shipments.Filters.Tabs.Date.DatePicker.Last7Days'):
                    case t('Shipments.Filters.Tabs.Date.DatePicker.Last30Days'):
                    case t('Shipments.Filters.Tabs.Date.DatePicker.ThisYear'):
                    case t('Shipments.Filters.Tabs.Date.DatePicker.CustomRange'):
                        setRange([null, null])
                        setRangeTag([])
                        break

                    // carriers
                    case 'Fedex':
                    case 'Sendex':
                    case 'DHL':
                    case 'Ups':
                    case 'Redpack':
                    case 'Carssa':
                    case 'Ivoy':
                    case 'Scm':
                    case 'Quiken':
                    case 'Ampm':
                    case 'Estafeta':
                    case 'Noventa9Minutos':
                    case 'MensajerosUrbanos':
                    case 'Uber':
                    case 'aExpress':
                    case 'Coordinadora':
                    case 'InterRapidisimo':
                    case 'RedServi':
                    case 'ServiEntrega':
                    case 'Vencedor':
                    case 'Paquetexpress':
                    case 'Jtexpress':
                    case 'Borzo':
                    case 'Interrapidisimo':
                    case 'Tresguerras':
                    case 'Treggo':
                    case 'Pickit':
                    case 'Moova':
                    case 'Picap':
                    case 'Olva':
                    case 'Nirex':
                    case 'Envia':
                    case 'test':
                        const tempCarriers = carrierSelected.filter(carrier => carrier !== tag)
                        setCarrierSelected(tempCarriers)
                        break
                    
                    // sorted prices 
                    case t('Shipments.Filters.Tabs.Prices.Lower'):
                    case t('Shipments.Filters.Tabs.Prices.Higher'):
                        const tempPrices = priceSelected.filter(statu => statu !== tag)
                        setPriceSelected(tempPrices)
                        break 
                    
                    // types  
                    case t('Movements.Table.Columns.Type.Shipment'):
                    case t('Movements.Table.Columns.Type.Overweight'):
                    case t('Movements.Table.Columns.Type.Discount'):
                    case t('Movements.Table.Columns.Type.Recharge'):
                    case t('Movements.Table.Columns.Type.Refund'):
                    case t('Movements.Table.Columns.Type.Sync'):
                    case t('Movements.Table.Columns.Type.Notification'):
                    case t('Movements.Table.Columns.Type.Referral'):
                    case t('Movements.Table.Columns.Type.UpdatedUser'):
                    case t('Movements.Table.Columns.Type.UnlockedUser'):
                    case t('Movements.Table.Columns.Type.BlockedUser'):
                        const tempTypes = typeSelected.filter(statu => statu !== tag)
                        setTypeSelected(tempTypes)
                        break 
                    
                    // shipments statu
                    case t('Shipments.Table.Columns.Status.Active'):
                    case t('Shipments.Table.Columns.Status.Delivered'):
                    case t('Shipments.Table.Columns.Status.InTransit'):
                    case t('Shipments.Table.Columns.Status.Exception'):
                        const tempShipmentStatus = shipmentStatuSelected.filter(statu => statu !== tag)
                        setShipmentStatuSelected(tempShipmentStatus)
                        break 
                    
                    // cancellations statu
                    case t('Cancellations.Table.Columns.Status.Solicited'):
                    case t('Cancellations.Table.Columns.Status.Refunded'):
                    case t('Cancellations.Table.Columns.Status.CancelledButUsed'):
                    case t('Cancellations.Table.Columns.Status.CancelledFromProvider'):
                        const tempCancellationStatus = cancellationStatuSelected.filter(statu => statu !== tag)
                        setCancellationStatuSelected(tempCancellationStatus)
                        break 
                    
                    // pickups statu
                    case t('Pickups.Table.Columns.Status.Scheduled'):
                    case t('Pickups.Table.Columns.Status.Solicited'):
                        const tempPickupStatus = pickupStatuSelected.filter(statu => statu !== tag)
                        setPickupStatuSelected(tempPickupStatus)
                        break 

                    // api shipments statu
                    case t('Shipments.Table.Columns.Status.Active'):
                    case t('Shipments.Table.Columns.Status.Delivered'):
                    case t('Shipments.Table.Columns.Status.InTransit'):
                    case t('Shipments.Table.Columns.Status.Exception'):
                    case t('Cancellations.Table.Columns.Status.Solicited'):
                    case t('Cancellations.Table.Columns.Status.Refunded'):
                    case t('Cancellations.Table.Columns.Status.CancelledButUsed'):
                    case t('Cancellations.Table.Columns.Status.CancelledFromProvider'):
                        const tempApiShipmentStatus = apiShipmentStatuSelected.filter(statu => statu !== tag)
                        setApiShipmentStatuSelected(tempApiShipmentStatus)
                        break
                    
                    // prospects statu
                    case t('Referrals.Table.Filters.Status.Pending'):
                    case t('Referrals.Table.Filters.Status.Approved'):
                    case t('Referrals.Table.Filters.Status.Refused'):
                        const tempReferralStatus = referralStatuSelected.filter(statu => statu !== tag)
                        setReferralStatuSelected(tempReferralStatus)
                        break 
                    
                    // withdrawal statu
                    // case t('Dashboard.Table.Withdrawals.Filters.Status.Pending'):
                    // case t('Dashboard.Table.Withdrawals.Filters.Status.Paid'):
                    // case t('Dashboard.Table.Withdrawals.Filters.Status.Refused'):
                    //     const tempWithdrawaltStatus = withdrawalStatuSelected.filter(statu => statu !== tag)
                    //     setWithdrawalStatuSelected(tempWithdrawaltStatus)
                    //     break 
                    
                    // reg method 
                    case t('Tracking.Table.Filters.Method.Google'):
                    case t('Tracking.Table.Filters.Method.Facebook'):
                    case t('Tracking.Table.Filters.Method.Auth'):
                        const tempRegMethod = regMethodSelected.filter(statu => statu !== tag)
                        setRegMethodSelected(tempRegMethod)
                        break 
                    
                    // app country
                    case t('Tracking.Table.Filters.Country.MX'):
                    case t('Tracking.Table.Filters.Country.CO'):
                    case t('Tracking.Table.Filters.Country.PE'):
                        const tempAppCountry = appCountrySelected.filter(statu => statu !== tag)
                        setAppCountrySelected(tempAppCountry)
                        break 
                    
                    // user activity
                    case t('Tracking.Table.Filters.UserActivity.NewUsers'):
                    case t('Tracking.Table.Filters.UserActivity.WithoutRecharging'):
                    case t('Tracking.Table.Filters.UserActivity.FirstRecharge'):
                        const tempUserActivity = userActivitySelected.filter(statu => statu !== tag)
                        setUserActivitySelected(tempUserActivity)
                        break 
                }
                break 
        } 
    }

    const getRangesTag = (range: [Date | null, Date | null]): void => {
        if (range[0] !== null && range[1] !== null) {
            const currentDate = new Date()
            const milliseconds: number = Math.abs(range[1].getTime() - range[0].getTime())
            const days: number = Math.floor(milliseconds / (1000 * 60 * 60 * 24))
            const initYear: Date = new Date(range[0].getFullYear(), 0, 1)
            const remainingStartOfYear: number = Math.floor(
                (range[0].getTime() - initYear.getTime()) / (1000 * 60 * 60 * 24)
            )

            const validationYear: boolean = remainingStartOfYear === days

            const isToday = days === 0
            const isYesterday = days === 1
            const isLast7Days = days === 7
            const isLast30Days = days === 30

            if (
                (isToday || isYesterday || isLast7Days || isLast30Days) &&
                range[1].getDate() === currentDate.getDate() &&
                range[1].getMonth() === currentDate.getMonth() &&
                range[1].getFullYear() === currentDate.getFullYear()
            ) {
                setTypeTags('Date')
                setRangeTag([
                isToday
                    ? t('Shipments.Filters.Tabs.Date.DatePicker.Today')
                    : isYesterday
                    ? t('Shipments.Filters.Tabs.Date.DatePicker.Yesterday')
                    : isLast7Days
                    ? t('Shipments.Filters.Tabs.Date.DatePicker.Last7Days')
                    : (t('Shipments.Filters.Tabs.Date.DatePicker.Last30Days') as TagDate)
                ])
            } else if (validationYear || remainingStartOfYear === 0) {
                setTypeTags('Date')
                setRangeTag([t('Shipments.Filters.Tabs.Date.DatePicker.ThisYear') as TagDate])
            } else {
                setTypeTags('Date')
                setRangeTag([t('Shipments.Filters.Tabs.Date.DatePicker.CustomRange') as TagDate])
            }
        }
    }

    const handleSubmit = async ()=> {
        let copyFilterByColumns: any = {}
        if (setFilterByColumns && stateValues && tableType){
            copyFilterByColumns = { ...stateValues?.filterByColumns}
            copyFilterByColumns.columns.forEach((column: any, index: number) => {
                // date 
                if (['shipments', 'cancellations', 'movements','pickups', 'apiShipments', 'profiles', 'referrals', 'withdrawals', 'tracking'].includes(tableType)){

                    switch(column.id){
                        case 'createdAt':
                        case 'created_at':
                            if (range[0] !== null && range[1] !== null){
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,
                                        filter_values: [
                                            range[0],
                                            range[1]
                                        ]
                                    }
                                }
                            } else {
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: false,
                                    filters: {
                                        sort: false,
                                        filter: false,
                                        filter_values: []
                                    }
                                }
                            }
                            break 
                    }
                }

                // carriers 
                if (['shipments', 'cancellations', 'apiShipments'].includes(tableType)){
                    switch(column.id){
                        case 'carrier':
                            if (carrierSelected.length > 0){
                                const carriers = allCarriersLabels
                                    .filter(item => carrierSelected.includes(item.label as TagParcel))
                                    .map(item => item.name)

                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,
                                        filter_values: carriers
                                    }
                                }
                            } else {
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: false,
                                    filters: {
                                        sort: false,
                                        filter: false,
                                        filter_values: []
                                    }
                                }
                            }
                            break 
                    }
                }

                // sorted price (higher, lower)
                if (['shipments', 'cancellations', 'movements', 'apiShipments'].includes(tableType)){
                    switch(column.id){
                        case 'price':
                        case 'quantity':
                            if (priceSelected.length > 0){
                                const option = ['Precios altos', 'High prices'].includes(priceSelected[0] ?? '') ? -1 : 1
                                
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: true,
                                        sorted: option,
                                        filter: true,
                                        filter_values: []
                                    }
                                }
                            } else {
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: false,
                                    filters: {
                                        sort: false,
                                        filter: false,  
                                        filter_values: []
                                    }
                                }
                            }
                            break 
                    }
                }
                
                // types
                if (['movements'].includes(tableType)){
                    switch(column.id){
                        case 'type':
                            if (typeSelected.length > 0){
                                let types: any[] = []
                                typeSelected.forEach((statu: any) => {
                                    if (['Shipment','Envio'].includes(statu)) types.push('ENVIO')
                                    else if (['Overweight','Sobrepeso'].includes(statu)) types.push('SOBREPESO')
                                    else if (['Discount','Descuento'].includes(statu)) types.push('DESCUENTO')
                                    else if (['Recharge','Recarga'].includes(statu)) types.push('RECARGA')
                                    else if (['Refund','Reembolso'].includes(statu)) types.push('REEMBOLSO')
                                    else if (['Balance synchronization','Sincronizacion de saldo'].includes(statu)) types.push('SINCRONIZACION DE SALDO')
                                    else if (['Notification','Notificacion'].includes(statu)) types.push('NOTIFICATION')
                                    else if (['Referral','Referido'].includes(statu)) types.push('REFERIDO')
                                    else if (['Updated user','Actualizacion de usuario'].includes(statu)) types.push('ACTUALIZACIÓN DE USUARIO')
                                    else if (['Unlocked user','Desbloqueo de usuario'].includes(statu)) types.push('DESBLOQUEO DE USUARIO')
                                    else if (['Blocked user','Bloqueo de usuario'].includes(statu)) types.push('BLOQUEO DE USUARIO')
                                })
                                
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,
                                        filter_values: types
                                    }
                                }
                            } else {
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,  
                                        filter_values: [
                                            'ENVIO',
                                            'SOBREPESO',
                                            'DESCUENTO',
                                            'RECARGA',
                                            'REEMBOLSO',
                                            'SINCRONIZACION DE SALDO',
                                            'NOTIFICATION',
                                            'REFERIDO',
                                            'ACTUALIZACIÓN DE USUARIO',
                                            'DESBLOQUEO DE USUARIO',
                                            'BLOQUEO DE USUARIO',
                                        ]
                                    }
                                }
                            }
                            break 
                    }
                }
                
                // shipment statu
                if (['shipments'].includes(tableType)){
                    switch(column.id){
                        case 'shipment_status':
                            if (shipmentStatuSelected.length > 0){
                                let status: any[] = []
                                shipmentStatuSelected.forEach((statu: any) => {
                                    if (['Active','Activa'].includes(statu)) status.push('Activa')
                                    else if (['Delivered','Entregado'].includes(statu)) status.push('Entregado') 
                                    else if (['In transit','En transito'].includes(statu)) status.push('En transito')
                                    else if (['Exception','Excepcion'].includes(statu)) status.push('Excepcion')
                                })
                                
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,
                                        filter_values: status
                                    }
                                }
                            } else {
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,  
                                        filter_values: ['Activa', 'Entregado', 'En transito', 'Excepcion']
                                    }
                                }
                            }
                            break 
                    }
                }
                
                // cancellation statu
                if (['cancellations'].includes(tableType)){
                    switch(column.id){
                        case 'cancellation_status':
                            if (cancellationStatuSelected.length > 0){
                                let status: any[] = []
                                cancellationStatuSelected.forEach((statu: any) => {
                                    if (['Solicited','Solicitada'].includes(statu)) status.push('Solicitada')
                                    else if (['C. but used','C. y usada'].includes(statu)) status.push('Cancelada y usada') 
                                    else if (['Refunded','Abonada'].includes(statu)) status.push('Saldo abonado')
                                    else if (['Cancelled from provider','Cancelada de prov'].includes(statu)) status.push('Cancelada de prov')
                                })
                                
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,
                                        filter_values: status
                                    }
                                }
                            } else {
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,  
                                        filter_values: ['Solicitada', 'Cancelada y usada', 'Saldo abonado', 'Cancelada de prov']
                                    }
                                }
                            }
                            break 
                    }
                }
                
                // pickup statu
                if (['pickups'].includes(tableType)){
                    switch(column.id){
                        case 'pickups_status':
                            if (pickupStatuSelected.length > 0){
                                let status: any[] = []
                                pickupStatuSelected.forEach((statu: any) => {
                                    if (['Agendado','Scheduled'].includes(statu)) status.push('Agendado')
                                    else if (['Solicitada','Solicited'].includes(statu)) status.push('Solicitada', 'En espera') 
                                    else if (['En espera','Waiting'].includes(statu)) status.push('Solicitada','En espera')
                                    else if (['Cancelada de prov','Cancelled from provider'].includes(statu)) status.push('Cancelada de prov')
                                    else if (['Sin disponibilidad','No availability'].includes(statu)) status.push('Sin disponibilidad')
                                })
                                
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,
                                        filter_values: status
                                    }
                                }
                            } else {
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,  
                                        filter_values: ['Agendado']
                                    }
                                }
                            }
                            break 
                    }
                }

                // api shipment statu
                if (['apiShipments'].includes(tableType)){
                    switch(column.id){
                        case 'shipment_status':
                            if (apiShipmentStatuSelected.length > 0){
                                let status: any[] = []
                                apiShipmentStatuSelected.forEach((statu: any) => {
                                    if (['Active','Activa'].includes(statu)) status.push('Activa')
                                    else if (['Delivered','Entregado'].includes(statu)) status.push('Entregado') 
                                    else if (['In transit','En transito'].includes(statu)) status.push('En transito')
                                    else if (['Exception','Excepcion'].includes(statu)) status.push('Excepcion')
                                    else if (['Solicited','Solicitada'].includes(statu)) status.push('Solicitada')
                                    else if (['C. but used','C. y usada'].includes(statu)) status.push('Cancelada y usada') 
                                    else if (['Refunded','Abonada'].includes(statu)) status.push('Saldo abonado')
                                    else if (['Cancelled from provider','Cancelada de prov'].includes(statu)) status.push('Cancelada de prov')
                                })
                                
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,
                                        filter_values: status
                                    }
                                }
                            } else {
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,  
                                        filter_values: [
                                            'Activa', 
                                            'Entregado', 
                                            'En transito', 
                                            'Excepcion',
                                            'Solicitada', 
                                            'Cancelada y usada', 
                                            'Saldo abonado', 
                                            'Cancelada de prov'
                                        ]
                                    }
                                }
                            }
                            break 
                    }
                }
                
                // prospects statu
                if (['referrals'].includes(tableType)){
                    switch(column.id){
                        case 'referralStatus':
                            if (referralStatuSelected.length > 0){
                                const status = referralStatuSelected.map((statu: any) => {
                                    if (['Pending','Pendiente'].includes(statu)) return 'pendiente'
                                    else if (['Approved','Aprobado'].includes(statu)) return 'aprobado'
                                    else if (['Refused','Rechazado'].includes(statu)) return 'rechazado'
                                    else return null
                                })
                                
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,
                                        filter_values: status
                                    }
                                }
                            } else {
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: false,
                                    filters: {
                                        sort: false,
                                        filter: false,  
                                        filter_values: []
                                    }
                                }
                            }
                            break 
                    }
                }

                // withdrawal statu
                if (['withdrawals'].includes(tableType)){
                    switch(column.id){
                        case 'withdrawalStatus':
                            if (withdrawalStatuSelected.length > 0){
                                const status = withdrawalStatuSelected.map((statu: any) => {
                                    if (['Pending','Pendiente'].includes(statu)) return 'pendiente'
                                    else if (['Paid','Abonado'].includes(statu)) return 'abonado'
                                    else if (['Refused','Rechazado'].includes(statu)) return 'rechazado'
                                    else return null
                                })
                                
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,
                                        filter_values: status
                                    }
                                }
                            } else {
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: false,
                                    filters: {
                                        sort: false,
                                        filter: false,  
                                        filter_values: []
                                    }
                                }
                            }
                            break 
                    }
                }

                // reg method 
                if (['tracking'].includes(tableType)){
                    switch(column.id){
                        case 'reg_method':
                            if (regMethodSelected.length > 0){
                                let methods: any[] = []
                                regMethodSelected.forEach((method: any) => {
                                    if (['Google','google'].includes(method)) methods.push('google')
                                    else if (['Facebook','facebook'].includes(method)) methods.push('facebook') 
                                    else if (['Auth0','auth0'].includes(method)) methods.push('auth0')
                                })
                                
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,
                                        filter_values: methods
                                    }
                                }
                            } else {
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: false,
                                    filters: {
                                        sort: false,
                                        filter: false,  
                                        filter_values: []
                                    }
                                }
                            }
                            break 
                    }
                }
                
                // app country 
                if (['tracking'].includes(tableType)){
                    switch(column.id){
                        case 'app_country':
                            if (appCountrySelected.length > 0){
                                let country: any[] = []
                                appCountrySelected.forEach((method: any) => {
                                    if (['Colombia','CO'].includes(method)) country.push('CO')
                                    else if (['Mexico','MX'].includes(method)) country.push('MX') 
                                    else if (['Peru','PE'].includes(method)) country.push('PE') 
                                })
                                
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,
                                        filter_values: country
                                    }
                                }
                            } else {
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: false,
                                    filters: {
                                        sort: false,
                                        filter: false,  
                                        filter_values: []
                                    }
                                }
                            }
                            break 
                    }
                }
                
                // user activity 
                if (['tracking'].includes(tableType)){
                    switch(column.id){
                        case 'user_activity':
                            if (userActivitySelected.length > 0){
                                let activities: any[] = []
                                userActivitySelected.forEach((method: any) => {
                                    if (['Nuevos usuarios','New users'].includes(method)) activities.push('nuevos_usuarios')
                                    else if (['Sin recargar','Without recharging'].includes(method)) activities.push('sin_recargar') 
                                    else if (['Primera recarga','First recharge'].includes(method)) activities.push('primera_recarga')
                                })
                                
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: true,
                                    filters: {
                                        sort: false,
                                        filter: true,
                                        filter_values: activities
                                    }
                                }
                            } else {
                                copyFilterByColumns.columns[index] = {
                                    id: column.id,
                                    find: false,
                                    filters: {
                                        sort: false,
                                        filter: false,  
                                        filter_values: []
                                    }
                                }
                            }
                            break 
                    }
                }
            })

            switch(currentAction[0].name){
                case 'Filter':
                    onChangePage(1)
                    copyFilterByColumns.range.from = 1
                    copyFilterByColumns.range.to = stateValues.rowsPerPages
                    setFilterByColumns(copyFilterByColumns)
                    toggleFiltersModal()
                    break 
            }
        }
    }

    const handleTableDescription = (tableType: TableType) => {
        switch (tableType) {
            case 'shipments':
                return t('Shipments.Filters.MainSubTitle')
            case 'cancellations':
                return t('Cancellations.Filters.MainSubTitle')
            case 'movements':
                return t('Movements.Filters.MainSubTitle')
            case 'pickups':
                return t('Pickups.Filters.MainSubTitle')
            case 'apiShipments':
                return t('Shipments.Filters.MainSubTitle')
            case 'profiles':
                return t('Profiles.Table.Filters.MainSubTitle')
            case 'referrals':
                return t('Referrals.Table.Filters.MainSubTitle')
            case 'withdrawals':
                return t('Withdrawals.Table.Filters.MainSubTitle')
            case 'tracking':
                return t('Tracking.Table.Filters.MainSubTitle')
        }
    }

    useEffect(() => {
        const allCarriersLabelsCountry = Object.values(allParcelsObject)
            .filter(carrier => carrier.country.includes(country))
            .map(carrier => carrier.commercial_name)
            
        setCarriersListOptions(allCarriersLabelsCountry)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country])

    useEffect(() => {
        setCurrentAction(allActions.filter((action) => action.name === actionSelected))
    }, [actionSelected])

    useEffect(()=> {
        setSelectedAllFilters([ 
            ...selectedRangeFilters, 
            ...selectedCarrierFilters,
            ...selectedPriceFilters,
            ...selectedTypeFilters,
            ...selectedShipmentStatuFilters, 
            ...selectedCancellationStatuFilters, 
            ...selectedPickupStatuFilters, 
            ...selectedApiShipmentStatuFilters, 
            ...selectedReferralStatuFilters, 
            ...selectedWithdrawalStatuFilters, 
            ...selectedRegMethodFilters, 
            ...selectedAppCountryFilters, 
            ...selectedUserActivityFilters, 
        ])
    }, [
        selectedRangeFilters, 
        selectedCarrierFilters,
        selectedPriceFilters,
        selectedTypeFilters,
        selectedShipmentStatuFilters,
        selectedCancellationStatuFilters,
        selectedPickupStatuFilters,
        selectedApiShipmentStatuFilters,
        selectedReferralStatuFilters, 
        selectedWithdrawalStatuFilters, 
        selectedRegMethodFilters, 
        selectedAppCountryFilters, 
        selectedUserActivityFilters,
    ])

    useEffect(() => {
        handleCleanTags('removeAllTags')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleFiltersModal]) 

    useEffect(() => {
        const box = tagsContainerRef.current
        if (box) {
        if (box.scrollHeight > box.clientHeight) {
            box.style.overflowY = 'auto'
        } else {
            box.style.overflowY = 'hidden'
        }
        }
    }, [selectedAllFilters])

    useEffect(()=> {
        if (typeTags === 'Date') setSelectedRangeFilters([...rangeTag])
        if (typeTags === 'Carrier') setSelectedCarrierFilters(carrierSelected)
        if (typeTags === 'Price') setSelectedPriceFilters(priceSelected)
        if (typeTags === 'Type') setSelectedTypeFilters(typeSelected)
        if (typeTags === 'ShipmentStatu') setSelectedShipmentStatuFilters(shipmentStatuSelected)
        if (typeTags === 'CancellationStatu') setSelectedCancellationStatuFilters(cancellationStatuSelected)
        if (typeTags === 'PickupStatu') setSelectedPickupStatuFilters(pickupStatuSelected)
        if (typeTags === 'ApiShipmentStatu') setSelectedApiShipmentStatuFilters(apiShipmentStatuSelected)
        if (typeTags === 'ReferralStatu') setSelectedReferralStatuFilters(referralStatuSelected)
        if (typeTags === 'WithdrawalStatu') setSelectedWithdrawalStatuFilters(withdrawalStatuSelected)
        if (typeTags === 'RegMethod') setSelectedRegMethodFilters(regMethodSelected)
        if (typeTags === 'AppCountry') setSelectedAppCountryFilters(appCountrySelected)
        if (typeTags === 'UserActivity') setSelectedUserActivityFilters(userActivitySelected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activateTags])
    
    const { TabPane } = Tabs

    return (
        <div style={{width: '100%', position: 'relative'}}>
            <div className='info-filter'>
                <Info 
                    style={{
                        fontSize: '18px', 
                        marginRight: '6px', 
                        verticalAlign: 'middle'
                    }}
                />
                {tableType && handleTableDescription(tableType)}
            </div>
            <div 
                ref={tagsContainerRef}
                style={{
                    minHeight: '22px', 
                    maxHeight: '53px', 
                    overflowY: 'hidden'
                }} 
            >
                {selectedAllFilters.map((tag) => (
                    <Tag
                        key={tag}
                        closable
                        onClose={() => cleanTag(tag)}
                        closeIcon={<span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>X</span>}
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            padding: '3px',
                            backgroundColor: tagsColor[Math.floor(Math.random() * tagsColor.length)],
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                        }}
                    >
                        {tag}
                    </Tag>
                ))}
            </div>
            <div className='containerTags' style={{ display: 'flex', height: '396px' }} >
                <Tabs style={{ width: '100%' }} >
                    {/* date */}
                    {(tableType && ['shipments', 'cancellations', 'movements', 'pickups', 'apiShipments', 'profiles', 'referrals', 'withdrawals'].includes(tableType)) && (
                        <TabPane tab={t('Shipments.Filters.Tabs.Date.Title')} key="date">    
                            <FilterByDate
                                getRangeFromOutside={setRange}
                                getRangeTag={getRangesTag}
                                onApplyFilter={()=> setActivateTags(!activateTags)} 
                            />
                        </TabPane>
                    )}

                    {/* carriers */}
                    {(tableType && ['shipments', 'cancellations', 'apiShipments'].includes(tableType)) && (
                        <TabPane tab={t('Shipments.Filters.Tabs.Carrier.Title')} key="carrier">    
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '337px'
                                }}
                            >
                                <InputContainer 
                                    type="select"
                                    valueContainerName={`all_carriers`}
                                    placeholder={t('Shipments.Filters.Tabs.Carrier.InputPlaceholder')}
                                    multiple={true}
                                    style={{ padding: '0.2rem' }}
                                    optionsList={carriersListOptions}
                                    defaultValue={[]}
                                    onChange={(carriers: string[]) => setCarriers(carriers)}
                                />
                                <Button
                                    className='applyCarrier-button'
                                    style={{
                                        position: 'absolute',
                                        bottom: '5%',
                                        right: '0%'
                                    }}
                                    onClick={() => {
                                        setTypeTags('Carrier')  
                                        setCarrierSelected(carriers as TagParcel[])
                                        setActivateTags(!activateTags)
                                    }}
                                >
                                    {t('Table.Filters.Apply')}
                                </Button>
                            </div>
                        </TabPane>
                    )}
                    
                    {/* sorted prices (higher, lower) */}
                    {(tableType && ['shipments', 'cancellations', 'movements', 'apiShipments'].includes(tableType)) && (
                        <TabPane tab={t('Shipments.Filters.Tabs.Prices.Title')} key="prices">    
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '337px'
                                }}
                            >
                                <InputContainer 
                                    type="select"
                                    valueContainerName={`all_prices`}
                                    placeholder={t('Shipments.Filters.Tabs.Prices.InputPlaceholder')}
                                    multiple={false}
                                    style={{ padding: '0.2rem' }}
                                    optionsList={[    
                                        t('Shipments.Filters.Tabs.Prices.Lower'), 
                                        t('Shipments.Filters.Tabs.Prices.Higher'), 
                                    ]}
                                    defaultValue={[]}
                                    onChange={(prices: string) => { setPrices([prices]) }}
                                />
                                <Button
                                    className='applyPrices-button'
                                    style={{
                                        position: 'absolute',
                                        bottom: '5%',
                                        right: '0%'
                                    }}
                                    onClick={() => {
                                        setTypeTags('Price')  
                                        setPriceSelected(prices as TagPrice[])
                                        setActivateTags(!activateTags)
                                    }}
                                >
                                    {t('Table.Filters.Apply')}
                                </Button>
                            </div>
                        </TabPane>
                    )}
                    
                    {/* types */}
                    {(tableType && ['movements'].includes(tableType)) && (
                        <TabPane tab={t('Movements.Filters.Tabs.Types.Title')} key="type">    
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '337px'
                                }}
                            >
                                <InputContainer 
                                    type="select"
                                    valueContainerName={`all_types`}
                                    placeholder={t('Movements.Filters.Tabs.Types.InputPlaceholder')}
                                    multiple={true}
                                    style={{ padding: '0.2rem' }}
                                    optionsList={[    
                                        t('Movements.Filters.Tabs.Type.Shipment'),
                                        t('Movements.Filters.Tabs.Type.Overweight'),
                                        t('Movements.Filters.Tabs.Type.Discount'),
                                        t('Movements.Filters.Tabs.Type.Recharge'),
                                        t('Movements.Filters.Tabs.Type.Refund'),
                                        t('Movements.Filters.Tabs.Type.Sync'),
                                        t('Movements.Filters.Tabs.Type.Notification'),
                                        t('Movements.Filters.Tabs.Type.Referral'),
                                        t('Movements.Filters.Tabs.Type.UpdatedUser'),
                                        t('Movements.Filters.Tabs.Type.UnlockedUser'),
                                        t('Movements.Filters.Tabs.Type.BlockedUser')
                                    ]}
                                    defaultValue={[]}
                                    onChange={(types: string[]) => { setTypes(types) }}
                                />
                                <Button
                                    className='applyTypes-button'
                                    style={{
                                        position: 'absolute',
                                        bottom: '5%',
                                        right: '0%'
                                    }}
                                    onClick={() => {
                                        setTypeTags('Type')  
                                        setTypeSelected(types as TagType[])
                                        setActivateTags(!activateTags)
                                    }}
                                >
                                    {t('Table.Filters.Apply')}
                                </Button>
                            </div>
                        </TabPane>
                    )}
                    
                    {/* shipment statu */}
                    {(tableType && ['shipments'].includes(tableType)) && (
                        <TabPane tab={t('Shipments.Filters.Tabs.ShipmentStatus.Title')} key="shipmentStatu">    
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '337px'
                                }}
                            >
                                <InputContainer 
                                    type="select"
                                    valueContainerName={`all_shipmentStatus`}
                                    placeholder={t('Shipments.Filters.Tabs.ShipmentStatus.InputPlaceholder')}
                                    multiple={true}
                                    style={{ padding: '0.2rem' }}
                                    optionsList={[    
                                        t('Shipments.Filters.Tabs.ShipmentStatus.Active'), 
                                        t('Shipments.Filters.Tabs.ShipmentStatus.Delivered'), 
                                        t('Shipments.Filters.Tabs.ShipmentStatus.InTransit'), 
                                        t('Shipments.Filters.Tabs.ShipmentStatus.Exception'), 
                                    ]}
                                    defaultValue={[]}
                                    onChange={(shipmentStatus: string[]) => { setShipmentStatus(shipmentStatus) }}
                                />
                                <Button
                                    className='applyShipmentStatus-button'
                                    style={{
                                        position: 'absolute',
                                        bottom: '5%',
                                        right: '0%'
                                    }}
                                    onClick={() => {
                                        setTypeTags('ShipmentStatu')  
                                        setShipmentStatuSelected(shipmentStatus as TagShipmentStatu[])
                                        setActivateTags(!activateTags)
                                    }}
                                >
                                    {t('Table.Filters.Apply')}
                                </Button>
                            </div>
                        </TabPane>
                    )}
                    
                    {/* cancellation statu */}
                    {(tableType && ['cancellations'].includes(tableType)) && (
                        <TabPane tab={t('Cancellations.Filters.Tabs.CancellationStatus.Title')} key="cancellationStatu">    
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '337px'
                                }}
                            >
                                <InputContainer 
                                    type="select"
                                    valueContainerName={`all_cancellationStatus`}
                                    placeholder={t('Cancellations.Filters.Tabs.CancellationStatus.InputPlaceholder')}
                                    multiple={true}
                                    style={{ padding: '0.2rem' }}
                                    optionsList={[    
                                        t('Cancellations.Filters.Tabs.CancellationStatus.Solicited'), 
                                        t('Cancellations.Filters.Tabs.CancellationStatus.Refunded'), 
                                        t('Cancellations.Filters.Tabs.CancellationStatus.CancelledButUsed'), 
                                        t('Cancellations.Filters.Tabs.CancellationStatus.CancelledFromProvider'), 
                                    ]}
                                    defaultValue={[]}
                                    onChange={(cancellationStatus: string[]) => { setCancellationStatus(cancellationStatus) }}
                                />
                                <Button
                                    className='applyCancellationStatus-button'
                                    style={{
                                        position: 'absolute',
                                        bottom: '5%',
                                        right: '0%'
                                    }}
                                    onClick={() => {
                                        setTypeTags('CancellationStatu')  
                                        setCancellationStatuSelected(cancellationStatus as TagCancellationStatu[])
                                        setActivateTags(!activateTags)
                                    }}
                                >
                                    {t('Table.Filters.Apply')}
                                </Button>
                            </div>
                        </TabPane>
                    )}
                    
                    {/* pickup statu */}
                    {(tableType && ['pickups'].includes(tableType)) && (
                        <TabPane tab={t('Pickups.Filters.Tabs.PickupStatus.Title')} key="pickupStatu">    
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '337px'
                                }}
                            >
                                <InputContainer 
                                    type="select"
                                    valueContainerName={`all_pickupStatus`}
                                    placeholder={t('Pickups.Filters.Tabs.PickupStatus.InputPlaceholder')}
                                    multiple={true}
                                    style={{ padding: '0.2rem' }}
                                    optionsList={[    
                                        t('Pickups.Filters.Tabs.PickupStatus.Scheduled'), 
                                        t('Pickups.Filters.Tabs.PickupStatus.Solicited'),  
                                    ]}
                                    defaultValue={[]}
                                    onChange={(pickupStatus: string[]) => { setPickupStatus(pickupStatus) }}
                                />
                                <Button
                                    className='applyPickupStatus-button'
                                    style={{
                                        position: 'absolute',
                                        bottom: '5%',
                                        right: '0%'
                                    }}
                                    onClick={() => {
                                        setTypeTags('PickupStatu')  
                                        setPickupStatuSelected(pickupStatus as TagPickupStatu[])
                                        setActivateTags(!activateTags)
                                    }}
                                >
                                    {t('Table.Filters.Apply')}
                                </Button>
                            </div>
                        </TabPane>
                    )}

                    {/* api shipment statu */}
                    {(tableType && ['apiShipments'].includes(tableType)) && (
                        <TabPane tab={t('Shipments.Filters.Tabs.ShipmentStatus.Title')} key="shipmentStatu">    
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '337px'
                                }}
                            >
                                <InputContainer 
                                    type="select"
                                    valueContainerName={`all_apiShipmentStatus`}
                                    placeholder={t('Shipments.Filters.Tabs.ShipmentStatus.InputPlaceholder')}
                                    multiple={true}
                                    style={{ padding: '0.2rem' }}
                                    optionsList={[    
                                        t('Shipments.Filters.Tabs.ShipmentStatus.Active'), 
                                        t('Shipments.Filters.Tabs.ShipmentStatus.Delivered'), 
                                        t('Shipments.Filters.Tabs.ShipmentStatus.InTransit'), 
                                        t('Shipments.Filters.Tabs.ShipmentStatus.Exception'), 
                                        t('Cancellations.Filters.Tabs.CancellationStatus.Solicited'), 
                                        t('Cancellations.Filters.Tabs.CancellationStatus.Refunded'), 
                                        t('Cancellations.Filters.Tabs.CancellationStatus.CancelledButUsed'), 
                                        t('Cancellations.Filters.Tabs.CancellationStatus.CancelledFromProvider'),
                                    ]}
                                    defaultValue={[]}
                                    onChange={(apiShipmentStatus: string[]) => { setApiShipmentStatus(apiShipmentStatus) }}
                                />
                                <Button
                                    className='applyShipmentStatus-button'
                                    style={{
                                        position: 'absolute',
                                        bottom: '5%',
                                        right: '0%'
                                    }}
                                    onClick={() => {
                                        setTypeTags('ApiShipmentStatu')  
                                        setApiShipmentStatuSelected(apiShipmentStatus as TagApiShipmentStatu[])
                                        setActivateTags(!activateTags)
                                    }}
                                >
                                    {t('Table.Filters.Apply')}
                                </Button>
                            </div>
                        </TabPane>
                    )}
                    
                    {/* referral statu */}
                    {(tableType && ['referrals'].includes(tableType)) && (
                        <TabPane tab={t('Referrals.Table.Filters.TabPane')} key="referralStatu">    
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '337px'
                                }}
                            >
                                <InputContainer 
                                    type="select"
                                    valueContainerName={`all_prospectStatus`}
                                    placeholder={t('Referrals.Table.Filters.Input.Placeholder')}
                                    multiple={true}
                                    style={{ padding: '0.2rem' }}
                                    optionsList={[    
                                        t('Referrals.Table.Filters.Status.Pending'), 
                                        t('Referrals.Table.Filters.Status.Approved'), 
                                        t('Referrals.Table.Filters.Status.Refused'), 
                                    ]}
                                    defaultValue={[]}
                                    onChange={(referralStatus: string[]) => {
                                        setReferralStatus(referralStatus) 
                                    }}
                                />
                                <Button
                                    className='applyReferralStatus-button'
                                    style={{
                                        position: 'absolute',
                                        bottom: '5%',
                                        right: '0%'
                                    }}
                                    onClick={() => {
                                        setTypeTags('ReferralStatu')  
                                        setReferralStatuSelected(referralStatus as TagReferralStatu[])
                                        setActivateTags(!activateTags)
                                    }}
                                >
                                    {t('Table.Filters.Apply')}
                                </Button>
                            </div>
                        </TabPane>
                    )}
                    
                    {/* withdrawal statu */}
                    {(tableType && ['withdrawals'].includes(tableType)) && (
                        <TabPane tab={t('Withdrawals.Table.Filters.TabPane')} key="withdrawalStatu">    
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '337px'
                                }}
                            >
                                <InputContainer 
                                    type="select"
                                    valueContainerName={`all_withdrawalStatus`}
                                    placeholder={t('Withdrawals.Table.Filters.Input.Placeholder')}
                                    multiple={true}
                                    style={{ padding: '0.2rem' }}
                                    optionsList={[    
                                        t('Withdrawals.Table.Filters.Status.Paid'),
                                        t('Withdrawals.Table.Filters.Status.Pending'),
                                        t('Withdrawals.Table.Filters.Status.Refused')
                                    ]}
                                    defaultValue={[]}
                                    onChange={(withdrawalStatus: string[]) => { setWithdrawalStatus(withdrawalStatus) }}
                                />
                                <Button
                                    className='applyWithdrawalStatus-button'
                                    style={{
                                        position: 'absolute',
                                        bottom: '5%',
                                        right: '0%'
                                    }}
                                    onClick={() => {
                                        setTypeTags('WithdrawalStatu')  
                                        setWithdrawalStatuSelected(withdrawalStatus as TagWithdrawalStatu[])
                                        setActivateTags(!activateTags)
                                    }}
                                >
                                    {t('Table.Filters.Apply')}
                                </Button>
                            </div>
                        </TabPane>
                    )}

                    {/* req method */}
                    {(tableType && ['tracking'].includes(tableType)) && (
                        <TabPane tab={t('Tracking.Filters.Tabs.RegMethod.Title')} key="regMethod">    
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '337px'
                                }}
                            >
                                <InputContainer 
                                    type="select"
                                    valueContainerName={`all_regMethod`}
                                    placeholder={t('Tracking.Filters.Tabs.RegMethod.InputPlaceholder')}
                                    multiple={true}
                                    style={{ padding: '0.2rem' }}
                                    optionsList={[    
                                        t('Tracking.Filters.Tabs.RegMethod.Google'), 
                                        t('Tracking.Filters.Tabs.RegMethod.Facebook'), 
                                        t('Tracking.Filters.Tabs.RegMethod.Auth'), 
                                    ]}
                                    defaultValue={[]}
                                    onChange={(regMethod: string[]) => { setRegMethod(regMethod) }}
                                />
                                <Button
                                    className='apply-button'
                                    style={{
                                        position: 'absolute',
                                        bottom: '5%',
                                        right: '0%'
                                    }}
                                    onClick={() => {
                                        setTypeTags('RegMethod')  
                                        setRegMethodSelected(regMethod as TagRegMethod[])
                                        setActivateTags(!activateTags)
                                    }}
                                >
                                    {t('Table.Filters.Apply')}
                                </Button>
                            </div>
                        </TabPane>
                    )}
                    
                    {/* app country */}
                    {(tableType && ['tracking'].includes(tableType)) && (
                        <TabPane tab={t('Tracking.Filters.Tabs.AppCountry.Title')} key="appCountry">    
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '337px'
                                }}
                            >
                                <InputContainer 
                                    type="select"
                                    valueContainerName={`all_appCountry`}
                                    placeholder={t('Tracking.Filters.Tabs.AppCountry.InputPlaceholder')}
                                    multiple={true}
                                    style={{ padding: '0.2rem' }}
                                    optionsList={[    
                                        t('Tracking.Filters.Tabs.AppCountry.CO'), 
                                        t('Tracking.Filters.Tabs.AppCountry.MX'), 
                                        t('Tracking.Filters.Tabs.AppCountry.PE'), 
                                    ]}
                                    defaultValue={[]}
                                    onChange={(appCountry: string[]) => { setAppCountry(appCountry) }}
                                />
                                <Button
                                    className='apply-button'
                                    style={{
                                        position: 'absolute',
                                        bottom: '5%',
                                        right: '0%'
                                    }}
                                    onClick={() => {
                                        setTypeTags('AppCountry')  
                                        setAppCountrySelected(appCountry as TagAppCountry[])
                                        setActivateTags(!activateTags)
                                    }}
                                >
                                    {t('Table.Filters.Apply')}
                                </Button>
                            </div>
                        </TabPane>
                    )}
                    
                    {/* user activity */}
                    {(tableType && ['tracking'].includes(tableType)) && (
                        <TabPane tab={t('Tracking.Filters.Tabs.UserActivity.Title')} key="userActivity">    
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '337px'
                                }}
                            >
                                <InputContainer 
                                    type="select"
                                    valueContainerName={`all_userActivity`}
                                    placeholder={t('Tracking.Filters.Tabs.UserActivity.InputPlaceholder')}
                                    multiple={true}
                                    style={{ padding: '0.2rem' }}
                                    optionsList={[    
                                        t('Tracking.Filters.Tabs.UserActivity.NewUsers'), 
                                        t('Tracking.Filters.Tabs.UserActivity.WithoutRecharging'), 
                                        t('Tracking.Filters.Tabs.UserActivity.FirstRecharge'), 
                                    ]}
                                    defaultValue={[]}
                                    onChange={(activity: string[]) => { setUserActivity(activity) }}
                                />
                                <Button
                                    className='apply-button'
                                    style={{
                                        position: 'absolute',
                                        bottom: '5%',
                                        right: '0%'
                                    }}
                                    onClick={() => {
                                        setTypeTags('UserActivity')  
                                        setUserActivitySelected(userActivity as TagUserActivity[])
                                        setActivateTags(!activateTags)
                                    }}
                                >
                                    {t('Table.Filters.Apply')}
                                </Button>
                            </div>
                        </TabPane>
                    )}
                </Tabs>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                    className="mainButtonTags"
                    onClick={handleSubmit}
                    icon={currentAction[0].payload.icon()}
                    disabled={
                        (selectedRangeFilters.length === 0) && 
                        (selectedCarrierFilters.length === 0) && 
                        (selectedPriceFilters.length === 0) && 
                        (selectedTypeFilters.length === 0) && 
                        (selectedShipmentStatuFilters.length === 0) &&
                        (selectedCancellationStatuFilters.length === 0) &&
                        (selectedPickupStatuFilters.length === 0) &&
                        (selectedApiShipmentStatuFilters.length === 0) &&
                        (selectedReferralStatuFilters.length === 0) &&
                        (selectedWithdrawalStatuFilters.length === 0) &&
                        (selectedRegMethodFilters.length === 0) && 
                        (selectedAppCountryFilters.length === 0) && 
                        (selectedUserActivityFilters.length === 0)
                    } 
                    style={{
                        backgroundColor: `
                            ${
                                (selectedRangeFilters.length === 0) && 
                                (selectedCarrierFilters.length === 0) && 
                                (selectedPriceFilters.length === 0) && 
                                (selectedTypeFilters.length === 0) && 
                                (selectedShipmentStatuFilters.length === 0) &&
                                (selectedCancellationStatuFilters.length === 0) &&
                                (selectedPickupStatuFilters.length === 0) &&
                                (selectedApiShipmentStatuFilters.length === 0) &&
                                (selectedReferralStatuFilters.length === 0) &&
                                (selectedWithdrawalStatuFilters.length === 0) && 
                                (selectedRegMethodFilters.length === 0) && 
                                (selectedAppCountryFilters.length === 0) && 
                                (selectedUserActivityFilters.length === 0)
                                    ? '#6d71f680' 
                                    : '#6d71f6'}
                        `,
                        color: 'white',
                        fontWeight: 'bold',
                        borderRadius: '20px',
                    }}
                >
                    {t('Table.Filters.ApplyFilters')}
                </Button>
            </div>
        </div>
    )
}   

export default TabsFilterContainer
