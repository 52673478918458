import { Col, Row, Typography } from "antd"
import { UserItemI } from "../Search/SearchItem";
import { FC, useEffect, useState } from "react";
import SectionLayout from "./SectionLayout";
import { dataToShowI, userActionsT } from "../interfaces";
import useDevice from "../../../../hooks/useDevice";
import { PluginInterfaceV2 } from "../../../../interfaces/plugin.interface";
import { useGet } from "../../../../hooks/useGet";
import { getPluginConfig } from "../../../../services/plugins_s";
import PackageTitles from "../Components/PluginsSection/PackageTitles";
import EmptyMsgContainer from "../Components/PluginsSection/EmptyMsgContainer";
import RateTitles from "../Components/PluginsSection/RateTitles";
import FreeShippingTitles from "../Components/PluginsSection/FreeShippingTitles";
import AddressCard from "../Components/PluginsSection/AddressCard";
import CarrierList from "../Components/PluginsSection/CarrierList";
import ServiceSkeletonList from "./SkeletonList";

interface props {
    userFound: UserItemI;
    fullUser: dataToShowI | null;
    changeActions: (type: userActionsT) => void;
    showDrawer: () => void;
}

interface Styles {
    title: React.CSSProperties;
    titleMobile: React.CSSProperties;
    container: React.CSSProperties;
    cardsList: React.CSSProperties;
    cardsListMobile: React.CSSProperties;

}

const styles: Styles = {
    title: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    titleMobile: {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    container: {
        width: '100%'
    },
    cardsList: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '1rem',
    },
    cardsListMobile: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        gap: '1rem',
        margin: '2rem 0'
    }

}

const PluginsPage: FC<props> = ({ userFound, changeActions, showDrawer, fullUser }) => {
    const { data } = useGet<PluginInterfaceV2[]>(getPluginConfig(userFound.user))
    const [pluginData, setPluginData] = useState<PluginInterfaceV2 | null>(null)
    const [showSkeleton, setShowSkeleton] = useState(true)
    const { isTablet } = useDevice()

    useEffect(() => {
        if (data && data?.length > 0) {
            setPluginData(data[0] as PluginInterfaceV2)
        }
        console.log(data)
    }, [data])

    useEffect(() => {
        setTimeout(() => {
            setShowSkeleton(false);
        }, 1000);
    }, [])

    return (
        <SectionLayout
            title="Plugins"
            userFound={userFound}
            changeActions={changeActions}
            showDrawer={showDrawer}
            roles={fullUser?.roles || []} >
                {
                    showSkeleton ? (
                        <ServiceSkeletonList />
                    ) : (
                        pluginData ?
                        <Row gutter={20} justify="space-around" wrap style={isTablet ? styles.cardsListMobile : styles.cardsList}>
                            <Col span={24}>
                                <Typography style={isTablet ? styles.titleMobile : styles.title}>
                                    Paqueterías nacionales
                                </Typography>
                            </Col>
                            <br />
                            <Col span={24}>
                                <CarrierList carriers={pluginData.carriers.national} />
                            </Col>
                            <br />
                            <Col span={24}>
                                <Typography style={isTablet ? styles.titleMobile : styles.title}>
                                    Paqueterías internacionales
                                </Typography>
                            </Col>
                            <br />
                            <Col span={24}>
                                <CarrierList carriers={pluginData.carriers.international} />
                            </Col>
                            <br />
                            <Col span={24}>
                                <Typography style={isTablet ? styles.titleMobile : styles.title}>
                                    Dirección de remitente
                                </Typography>
                            </Col>
                            <br />
                            <Col span={24}>
                                <AddressCard data={pluginData} />
                            </Col>
                            <br />
                            <Col span={24}>
                                <Typography style={isTablet ? styles.titleMobile : styles.title}>
                                    Paquetes asignados
                                </Typography>
                            </Col>
                            <br />
                            <Col span={24}>
                                {
                                    pluginData.packages && pluginData.packages.length > 0 ?
                                        pluginData.packages.map(item => (
                                            <PackageTitles item={item} country={pluginData.country} />
                                        ))

                                        : <EmptyMsgContainer>
                                            No hay reglas de paquetes asignadas, las cotizaciones seràn de 10x10x10cm
                                        </EmptyMsgContainer>
                                }
                            </Col>
                            <br />
                            <Col span={24}>
                                <Typography style={isTablet ? styles.titleMobile : styles.title}>
                                    Reglas de tarifas
                                </Typography>
                            </Col>
                            <br />
                            <Col span={24}>
                                {
                                    pluginData.rates_rules && pluginData.rates_rules.length > 0 ?
                                        pluginData.rates_rules.map(item => (
                                            <RateTitles item={item} country={pluginData.country} />
                                        ))

                                        : <EmptyMsgContainer>
                                            No hay reglas de tarifas configuradas.
                                        </EmptyMsgContainer>
                                }
                            </Col>
                            <br />
                            <Col span={24}>
                                <Typography style={isTablet ? styles.titleMobile : styles.title}>
                                    Reglas de envío gratuito
                                </Typography>
                            </Col>
                            <br />
                            <Col span={24}>
                                {
                                    pluginData.free_shipping && pluginData.free_shipping.length > 0 ?
                                        pluginData.free_shipping.map(item => (
                                            <FreeShippingTitles item={item} country={pluginData.country} />
                                        ))

                                        : <EmptyMsgContainer>
                                            No hay reglas de envío gratuito asignadas.
                                        </EmptyMsgContainer>
                                }
                            </Col>
                            <br />
                            <Col span={24}>
                                <Typography style={isTablet ? styles.titleMobile : styles.title}>
                                    Reglas de entrega en sucursal
                                </Typography>
                            </Col>
                            <br />
                            <Col span={24}>
                                {
                                    pluginData.delivery_in_store && pluginData.delivery_in_store.length > 0 ?
                                        pluginData.delivery_in_store.map(item => (
                                            <FreeShippingTitles item={item} country={pluginData.country} />
                                        ))

                                        : <EmptyMsgContainer>
                                            No hay reglas de entrega en sucursal asignadas.
                                        </EmptyMsgContainer>
                                }
                            </Col>
                        </Row> :
                        <EmptyMsgContainer>
                            El usuario no tiene Plugins configurados en esta cuenta.
                        </EmptyMsgContainer>
                )
                }

        </SectionLayout>
    )
}

export default PluginsPage