import { FC, PropsWithChildren } from "react"
import CardContainer from "../../../../../components/Containers/CardContainer"

const styles = {
    emptyMsgContainer: {
        border: '1px solid #E8E8E8',
        borderLeft: '1px solid #E8E8E8',
        borderRadius: '12px',
        padding: '20px',
        paddingBottom: '20px',
        boxShadow: '0 0 0',
        fontSize: '0.9rem'
    }
}


const EmptyMsgContainer: FC<PropsWithChildren<any>> = ({ children }) => {
    return (
        <CardContainer cardStyle={styles.emptyMsgContainer}>
            {children}
        </CardContainer>
    )
}

export default EmptyMsgContainer