import { FC, useEffect, useState } from "react"
import CardContainer from "../../../../../components/Containers/CardContainer"
import { PluginInterfaceV2 } from "../../../../../interfaces/plugin.interface"
import { AddressesInterface } from "../../../../../interfaces/shipments.interface"
import { switchCountry } from "./utils"
import { Col, Row } from "antd"
import EmptyMsgContainer from "./EmptyMsgContainer"
import { states } from "../../../../../constants/statesMx"

const styles = {
    header: {
        color: '#2E1D6E',
        fontWeight: 'semi-bold',
        fontSize: '1.8em',
        marginTop: '20px'
    },
    inputField: {
        borderRadius: '12px',
        backgroundColor: '#4F31BF',
        color: '#fff',
        padding: '16px 17px',
        fontWeight: 'semi-bold',
        width: '100%',
        marginLeft: '14px',
        itemsCenter: 'center',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    title: {
        display: 'flex'
    },
    editContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.4rem'
    },
    addressContainer: {
        border: '1px solid #E8E8E8',
        borderLeft: '1px solid #E8E8E8',
        borderRadius: '12px',
        padding: '20px',
        paddingBottom: '20px',
        boxShadow: '0 0 0',
        fontSize: '0.9rem'
    },

    detailAddress: {
        backgroundColor: '#F5F5F5',
        borderRadius: '12px',
        border: '1px solid #E1E1E1',
        padding: '26px',
        marginTop: '32px',
        paddingBottom: '100px'
    }
}

interface props {
    data: PluginInterfaceV2
    refetch?: () => void
    isLoading?: boolean
}

const AddressCard: FC<props> = ({ data }) => {
    const [address, setAddress] = useState<AddressesInterface | null>(null)

    useEffect(() => {
        if (data?.address) {
            setAddress(data?.address as any)
        }
    },[data.address])
    return (
        <CardContainer cardStyle={styles.addressContainer}>
            {address ? (
                <>
                    <article>
                        <section
                            style={{
                                display: 'flex',
                                gap: '1rem',
                                alignItems: 'center'
                            }}
                        >
                            <img src={switchCountry(data.country)} alt="Country" width={48} height={48} />
                            <div style={styles.inputField}>
                                <span style={styles.title}>{address.title} </span>
                            </div>
                        </section>
                        <Row style={styles.detailAddress}>
                            <Col sm={24} md={24} lg={24} xl={12} xxl={12}>
                                <div>
                                    <strong>Nombre Completo</strong>
                                    <p>{address.name}</p>
                                </div>
                                <div>
                                    <strong>Teléfono</strong>
                                    <p>{address.phone}</p>
                                </div>
                                <div>
                                    <strong>Calle</strong>
                                    <p>{address.street}</p>
                                </div>
                                <div>
                                    <strong>Colonia</strong>
                                    <p>{address.district}</p>
                                </div>
                                <div>
                                    <strong>Delegación - Ciudad</strong>
                                    <p>{address.city}</p>
                                </div>
                                <div>
                                    <strong>País</strong>
                                    <p>{address.country}</p>
                                </div>
                            </Col>
                            <Col sm={24} md={24} lg={24} xl={12} xxl={12}>
                                <div>
                                    <strong>Correo Electrónico</strong>
                                    <p>{address.email}</p>
                                </div>
                                <div>
                                    <strong>Empresa</strong>
                                    <p>{address.company}</p>
                                </div>
                                <div>
                                    <strong>Número interior</strong>
                                    <p>{address.int_number || 'N/A'}</p>
                                </div>
                                <div>
                                    <strong>Código postal</strong>
                                    <p>{address.postal_code}</p>
                                </div>
                                <div>
                                    <strong>Estado</strong>
                                    <p>{
                                        address.country === 'MX' ?
                                            states.MX[`${address.state.toUpperCase()}`] :
                                            address.country === 'CO' ?
                                                states.CO[`${address.state.toUpperCase()}`] :
                                                states.PE[`${address.state.toUpperCase()}`]
                                    }</p>
                                </div>
                                <div>
                                    <strong>Referencia</strong>
                                    <p>{address.reference}</p>
                                </div>
                            </Col>
                        </Row>
                    </article>
                </>
            ) : (
                <EmptyMsgContainer>
                    Aún no se ha asignado una dirección de remitente.
                </EmptyMsgContainer>
            )}
        </CardContainer>
    )
}

export default AddressCard