import { Typography } from "antd";
import { FC, useEffect, useState } from "react"
import FlagIcons from "./FlagIcons";
import { CountryEnum, Plugin_packagesV2 } from "../../../../../interfaces/plugin.interface";

const styles = {
    container: {
        width: '100%',
        border: '1px solid #E8E8E8',
        borderLeft: '1px solid #E8E8E8',
        borderRadius: '12px',
        padding: '20px',
        paddingBottom: '20px',
        boxShadow: '0 0 0',
        fontSize: "0.9rem",
    }
}

interface props {
    item: Plugin_packagesV2;
    country: CountryEnum;
}

const PackageTitles: FC<props> = ({ item, country }) => {
    const [userData, setUserData] = useState({app_country: country})

    useEffect(() => {
        if(country){
            setUserData({
                app_country: country
            })
        }
    }, [country])

    return (
        <section style={{
            display: 'flex',
            justifyContent: 'flex-start',
            textAlign: 'start',
            fontSize: '0.9rem',
            marginBottom: '12px'
        }}>
            {
                <div style={styles.container}>
                    <Typography>
                        <FlagIcons item={item} userData={userData} />
                        {' '} Paquete de <strong>{item.rules.dimensions?.width}x{item.rules.dimensions?.height}x{item.rules.dimensions?.length}cm</strong> en coptizaciones de <strong>{item.rules?.weight_range.first}kg</strong> hasta <strong>{item.rules?.weight_range.end}kg</strong>
                    </Typography>
                </div>

            }
        </section>

    )
}

export default PackageTitles