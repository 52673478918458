import { Col, Row, Typography } from "antd"
import { UserItemI } from "../Search/SearchItem";
import { FC, useEffect, useState } from "react";
import SectionLayout from "./SectionLayout";
import { dataToShowI, PreferencesCarriersV2, PreferencesInterface, userActionsT } from "../interfaces";
import { useGet } from "../../../../hooks/useGet";
import { getUserPreferencesV2 } from "../../../../services/user_d";
import useDevice from "../../../../hooks/useDevice";
import CarrierSkeletonList from "../Components/PreferencesAdmin/CarrierSkeletonList";
import { allCarriersObject } from "../../../../utils/carriers";
import UserCarrierCard from "../Components/PreferencesAdmin/UserCarrierCard";

interface props {
    userFound: UserItemI;
    fullUser: dataToShowI | null;
    changeActions: (type: userActionsT) => void;
    showDrawer: () => void;
}

export interface CarrierPreferenceI {
    active: boolean;
    ignore_api: boolean;
    active_admin: boolean;
    name: string;
    label_format: string;
    services: any[];
    personal_accounts: any[];
}


interface Styles {
    title: React.CSSProperties;
    titleMobile: React.CSSProperties;
    container: React.CSSProperties;
    cardsList: React.CSSProperties;
    cardsListMobile: React.CSSProperties;

}

const styles: Styles = {
    title: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    titleMobile: {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    container: {
        width: '100%'
    },
    cardsList: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '1rem',
        margin: '2rem 0'
    },
    cardsListMobile: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        gap: '1rem',
        margin: '2rem 0'
    }

}


const PreferencesPage: FC<props> = ({ userFound, changeActions, showDrawer, fullUser }) => {
    const { isLoading, data } = useGet<PreferencesInterface>(getUserPreferencesV2(userFound.user))
    const [carriers, setCarriers] = useState<PreferencesCarriersV2[]>([])
    const [showSkeleton, setShowSkeleton] = useState(true)
    const { isTablet } = useDevice()

    useEffect(() => {
        setTimeout(() => {
            setShowSkeleton(false);
        }, 1000);
    }, [])

    useEffect(() => {
        if (data) {
            const carriersFound = data.carriers
            let newCarriers: PreferencesCarriersV2[]

            if (carriersFound.length === 0) {
                newCarriers = allCarriersObject.National[`${userFound.app_country}` as 'MX' | 'CO' | 'PE'].map(carrier => {
                    return {
                        active: true,
                        ignore_api: false,
                        active_admin: true,
                        name: carrier,
                        label_format: "",
                        services: [],
                        personal_accounts: []
                    }
                })

            } else {
                newCarriers = allCarriersObject.National[`${userFound.app_country}` as 'MX' | 'CO' | 'PE'].map(carrier => {
                    return {
                        active: carriersFound.find(el => el.name === carrier)?.active || false,
                        ignore_api: carriersFound.find(el => el.name === carrier)?.ignore_api || false,
                        active_admin: carriersFound.find(el => el.name === carrier)?.active_admin || false,
                        name: carrier,
                        label_format: carriersFound.find(el => el.name === carrier)?.label_format || "",
                        services: carriersFound.find(el => el.name === carrier)?.services || [],
                        personal_accounts: carriersFound.find(el => el.name === carrier)?.personal_accounts || []
                    }
                })
            }

            setCarriers(newCarriers)
        }

    }, [data]) //eslint-disable-line

    return (
        <SectionLayout
            title="Preferencias Usuario"
            userFound={userFound}
            changeActions={changeActions}
            showDrawer={showDrawer}
            roles={fullUser?.roles || []}>
            {
                showSkeleton ? (
                    <Row gutter={20} justify="space-around" wrap style={isTablet ? styles.cardsListMobile : styles.cardsList}>
                        <CarrierSkeletonList />
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col span={24}>
                                <Typography style={isTablet ? styles.titleMobile : styles.title}>
                                    Preferencias de usuario
                                </Typography>
                            </Col>
                        </Row>
                        <Row gutter={20} justify="space-around" wrap style={isTablet ? styles.cardsListMobile : styles.cardsList}>
                            {
                                isLoading ? <CarrierSkeletonList /> : carriers.map(carrier => (
                                    <>
                                        <UserCarrierCard carrier={carrier} />
                                    </>

                                ))
                            }
                        </Row>
                    </>

                )
            }


        </SectionLayout>
    )
}

export default PreferencesPage