import ServiceSkeleton from "./ServiceSkeleton"

const ServiceSkeletonList = () => {
    return (
        <>
            <ServiceSkeleton />
            <ServiceSkeleton />
            <ServiceSkeleton />
            <ServiceSkeleton />
            <ServiceSkeleton />
            <ServiceSkeleton />
            <ServiceSkeleton />
        </>
    )
}

export default ServiceSkeletonList