import { Col, notification, Row, Typography } from "antd"
import { UserItemI } from "../Search/SearchItem";
import { FC, useEffect, useState } from "react";
import SectionLayout from "./SectionLayout";
import { dataToShowI, PreferencesCarriersV2, PreferencesInterface, userActionsT } from "../interfaces";
import useDevice from "../../../../hooks/useDevice";
import { allCarriersObject } from "../../../../utils/carriers";
import AdminCarrierCard from "../Components/PreferencesAdmin/AdminCarrierCard";
import { useGet } from "../../../../hooks/useGet";
import { getUserPreferencesV2, updatePreferencesV2 } from "../../../../services/user_d";
import { usePatch } from "../../../../hooks/usePatch";
import CarrierSkeletonList from "../Components/PreferencesAdmin/CarrierSkeletonList";

interface props {
    userFound: UserItemI;
    fullUser: dataToShowI | null;
    changeActions: (type: userActionsT) => void;
    showDrawer: () => void;
}

export interface CarrierPreferenceI {
    active: boolean;
    ignore_api: boolean;
    active_admin: boolean;
    name: string;
    label_format: string;
    services: any[];
    personal_accounts: any[];
}


interface Styles {
    title: React.CSSProperties;
    titleMobile: React.CSSProperties;
    container: React.CSSProperties;
    cardsList: React.CSSProperties;
    cardsListMobile: React.CSSProperties;

}

const styles: Styles = {
    title: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    titleMobile: {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    container: {
        width: '100%'
    },
    cardsList: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '1rem',
        margin: '2rem 0'
    },
    cardsListMobile: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        gap: '1rem',
        margin: '2rem 0'
    }

}


const PreferencesAdminPage: FC<props> = ({ userFound, changeActions, showDrawer, fullUser }) => {
    const { isTablet } = useDevice()
    const [carriers, setCarriers] = useState<PreferencesCarriersV2[]>([])
    const [showSkeleton, setShowSkeleton] = useState(true)
    const { isLoading, data } = useGet<PreferencesInterface>(getUserPreferencesV2(userFound.user))
    const { fetchDataPatch } = usePatch(updatePreferencesV2)

    useEffect(() => {
        setTimeout(() => {
            setShowSkeleton(false);
        }, 1000);
    }, [])

    useEffect(() => {
        if (data) {
            const carriersFound = data.carriers
            let newCarriers: PreferencesCarriersV2[]

            if (carriersFound.length === 0) {
                newCarriers = allCarriersObject.National[`${userFound.app_country}` as 'MX' | 'CO' | 'PE'].map(carrier => {
                    return {
                        active: true,
                        ignore_api: false,
                        active_admin: true,
                        name: carrier,
                        label_format: "",
                        services: [],
                        personal_accounts: []
                    }
                })

            } else {
                newCarriers = allCarriersObject.National[`${userFound.app_country}` as 'MX' | 'CO' | 'PE'].map(carrier => {
                    return {
                        active: carriersFound.find(el => el.name === carrier)?.active || false,
                        ignore_api: carriersFound.find(el => el.name === carrier)?.ignore_api || false,
                        active_admin: carriersFound.find(el => el.name === carrier)?.active_admin || false,
                        name: carrier,
                        label_format: carriersFound.find(el => el.name === carrier)?.label_format || "",
                        services: carriersFound.find(el => el.name === carrier)?.services || [],
                        personal_accounts: carriersFound.find(el => el.name === carrier)?.personal_accounts || []
                    }
                })
            }

            setCarriers(newCarriers)
        }

    }, [data]) //eslint-disable-line

    useEffect(() => {
        console.log("carriers", JSON.stringify(carriers))
    }, [carriers])

    const onChangeCarrier = async (name: string, key: string, value: boolean) => {
        setCarriers([
            ...carriers.map(el => {
                if (el.name === name) {
                    return {
                        ...el,
                        [key]: value

                    }
                }
                return el
            })
        ])
        await updateCarriers()
    }

    const updateCarriers = async () => {
        try {
            const updatePreferences = await fetchDataPatch(
                userFound?.user as string,
                carriers
            )
            if (updatePreferences) {
                notification.success({
                    message: 'Las preferencias se han actualizado',
                });

            } else {
                notification.error({
                    message: 'La actualización ha fallado',
                });
            }
        } catch (error) {
            console.log(error)
            notification.error({
                message: 'La actualización ha fallado',
            });
        }
    }

    return (
        <SectionLayout
            title="Bloqueo de preferencias"
            userFound={userFound}
            changeActions={changeActions}
            showDrawer={showDrawer}
            roles={fullUser?.roles || []}>
            {
                showSkeleton ? (
                    <Row gutter={20} justify="space-around" wrap style={isTablet ? styles.cardsListMobile : styles.cardsList}>
                        <CarrierSkeletonList />
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col span={24}>
                                <Typography style={isTablet ? styles.titleMobile : styles.title}>
                                    Preferencias de Administrador
                                </Typography>
                            </Col>
                        </Row>
                        <Row gutter={20} justify="space-around" wrap style={isTablet ? styles.cardsListMobile : styles.cardsList}>
                            {
                                isLoading ? <CarrierSkeletonList /> : carriers.map(carrier => (
                                    <>
                                        <AdminCarrierCard carrier={carrier} onChange={onChangeCarrier} />
                                    </>

                                ))
                            }
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Typography style={isTablet ? styles.titleMobile : styles.title}>
                                    Preferencias de API
                                </Typography>
                            </Col>
                        </Row>
                        <Row gutter={20} justify="space-around" wrap style={isTablet ? styles.cardsListMobile : styles.cardsList}>
                            {
                                isLoading ? <CarrierSkeletonList /> : carriers.map(carrier => (
                                    <>
                                        <AdminCarrierCard carrier={carrier} isApi={true} onChange={onChangeCarrier} />
                                    </>
                                ))
                            }
                        </Row>
                    </>
                )
            }

        </SectionLayout>
    )
}

export default PreferencesAdminPage