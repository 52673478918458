import { Col, Row, Typography } from "antd"
import { UserItemI } from "../Search/SearchItem";
import { FC, useEffect, useState } from "react";
import SectionLayout from "./SectionLayout";
import { dataToShowI, SpecialPricesI, SpecialPricesServicesI, SpecialPricesUserPricesI, userActionsT } from "../interfaces";
import { useGet } from "../../../../hooks/useGet";
import { getUserSpecialPricesV2 } from "../../../../services/user_d";
import useDevice from "../../../../hooks/useDevice";
import { carrierServices } from "../../../../services/records";
import CarrierAvatar from "../Components/CarrierAvatar";
import { currencyName } from "../utils";
import CarrierSkeletonList from "../Components/PreferencesAdmin/CarrierSkeletonList";
import { useMediaQuery } from "react-responsive";
import EmptyMsgContainer from "../Components/PluginsSection/EmptyMsgContainer";
import ServiceSkeletonList from "./SkeletonList";

interface props {
    userFound: UserItemI;
    fullUser: dataToShowI | null;
    changeActions: (type: userActionsT) => void;
    showDrawer: () => void;
}

interface Styles {
    title: React.CSSProperties;
    titleMobile: React.CSSProperties;
    container: React.CSSProperties;
    cardsList: React.CSSProperties;
    cardsListMobile: React.CSSProperties;
    card: React.CSSProperties;
    cardMobile: React.CSSProperties;
    serviceCard: React.CSSProperties;
    pricesCard: React.CSSProperties;
    priceItem: React.CSSProperties;
    listContainer: React.CSSProperties;
    cardHeader: React.CSSProperties;
    listMobile: React.CSSProperties;
    noSpecialPrices: React.CSSProperties;

}

const styles: Styles = {
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '1rem'
    },
    titleMobile: {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '1rem'
    },
    container: {
        width: '100%'
    },
    cardsList: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '1rem',
        margin: '2rem 0'
    },
    cardsListMobile: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        gap: '1rem',
        margin: '2rem 0'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        border: '1px solid #DFDCDC',
        padding: '1rem',
        margin: '10px 0',
        borderRadius: '.5rem',
        width: '15rem',
        marginTop: '20px'
    },
    cardMobile: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        border: '1px solid #DFDCDC',
        padding: '1rem',
        margin: '10px 0',
        borderRadius: '.5rem',
        width: '100%',
        marginTop: '20px'
    },
    serviceCard: {
        padding: '0 0 16px 0',
        border: '1px solid #DFDCDC',
        borderWidth: '1px 0 0 0',
    },
    pricesCard: {

    },
    priceItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '3rem',
        border: '1px solid #DFDCDC',
        borderWidth: '0 0 1px 0',
        fontWeight: '600',
        fontSize: '14px'
    },
    listContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(15rem, 1fr))",
        gap: "16px",
        alignItems: "start",
        width: '100%',
        gridAutoFlow: "dense"
    },
    listMobile: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%"
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem'
    },
    noSpecialPrices: {
        width: '100%'
    }

}

const SpecialPricesPage: FC<props> = ({ userFound, changeActions, showDrawer, fullUser }) => {
    const { isLoading, data } = useGet<SpecialPricesI[]>(getUserSpecialPricesV2(userFound.user))
    const [specialPrices, setSpecialPrices] = useState<SpecialPricesServicesI[]>([]);
    const [showSkeleton, setShowSkeleton] = useState(true)
    const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1199px)' })
    const {
        isTablet,
        // isMobile 
    } = useDevice()

    useEffect(() => {
        if (data) {
            if (data.length === 0) {
                setSpecialPrices([])
            } else {
                let values = reorganizedSpecialPrices(data)
                setSpecialPrices(sortItemsBySpecialPrices(values))
            }

        }

    }, [data]) //eslint-disable-line

    useEffect(() => {
        setTimeout(() => {
            setShowSkeleton(false);
        }, 1000);
    }, [])

    const reorganizedSpecialPrices = (items: SpecialPricesI[]): SpecialPricesServicesI[] => {
        let specialPrices: SpecialPricesServicesI[] = []
        let specialPricesWithoutDuplicates: any = {}

        for (let item of items) {
            for (let item2 of item.services) {
                for (let item3 of item2.services) {
                    specialPrices.push(item3)
                }
            }
        }
        specialPrices.forEach(item => {
            specialPricesWithoutDuplicates[`${item.service_label}_${carrierServices(item.service_name)}`] = item;
        });

        return Object.values(specialPricesWithoutDuplicates)
    }

    function groupPricesByWeightAndPrice(prices: SpecialPricesUserPricesI[]): { weight: string, price: string }[] {
        // Ordenar los precios por peso
        prices.sort((a, b) => a.weight - b.weight);

        const result: any[] = [];
        let rangeStart = prices[0].weight;
        let currentPrice = prices[0].price;
        let previousWeight = prices[0].weight;

        for (let i = 1; i <= prices.length; i++) {
            const currentItem = prices[i];
            const isLastItem = i === prices.length;

            if (isLastItem || currentItem.price !== currentPrice || currentItem.weight !== previousWeight + 1) {
                // Si hay un rango (más de un peso), mostrar el rango
                if (rangeStart !== previousWeight) {
                    result.push({ weight: `${rangeStart} - ${previousWeight}kg`, price: `${formatFinalPrice(currentPrice)}` });
                } else {
                    // Si no hay rango, mostrar un solo peso
                    result.push({ weight: `${rangeStart}kg`, price: `${formatFinalPrice(currentPrice)}` });
                }

                // Reiniciar el rango
                if (!isLastItem) {
                    rangeStart = currentItem.weight;
                    currentPrice = currentItem.price;
                    previousWeight = currentItem.weight;
                }
            } else {
                previousWeight = currentItem.weight;
            }
        }

        return result;
    }

    function formatFinalPrice(price: number) {
        let isFixedPrice = price > 0.99
        return `${isFixedPrice ? price + ' ' + currencyName(userFound.app_country) : (price * 100) + '%'}`
    }

    function sortItemsBySpecialPrices(items: any[]): any[] {
        return items.sort((a, b) => {
            const pricesA = groupPricesByWeightAndPrice(a.specialPrices[0].prices).length;
            const pricesB = groupPricesByWeightAndPrice(b.specialPrices[0].prices).length;

            // Ordenar de menor a mayor, los que tienen más "prices" irán al final
            return pricesA - pricesB;
        });
    }


    return (
        <SectionLayout
            title="Precios especiales"
            userFound={userFound}
            changeActions={changeActions}
            showDrawer={showDrawer}
            roles={fullUser?.roles || []}>
            <Row>
                <Col span={24}>
                    <Typography style={isTablet ? styles.titleMobile : styles.title}>
                        Precios asignados
                    </Typography>
                </Col>
                {
                    showSkeleton ? (
                        <Col span={24}>
                            <ServiceSkeletonList />
                        </Col>

                    ) : (
                        <Row style={
                            specialPrices.length === 0 ? { width: '100%' } : isMobileOrTablet ? styles.listMobile : styles.listContainer}>
                            {
                                isLoading ? <CarrierSkeletonList /> : specialPrices.length === 0 ? (

                                    <Col span={24} style={styles.noSpecialPrices}>
                                        <EmptyMsgContainer>
                                            El usuario no tiene precios especiales asignados
                                        </EmptyMsgContainer>

                                    </Col>



                                ) : specialPrices.map(item => (
                                    <article style={isMobileOrTablet ? styles.cardMobile : styles.card} key={item._id} >
                                        <section style={styles.cardHeader}>
                                            <CarrierAvatar
                                                carrier={item.label}
                                                country={userFound.app_country}
                                            />
                                            <div>
                                                <Typography><strong>{item.service_label}</strong></Typography>
                                                <Typography>{carrierServices(item.service_name)}</Typography>
                                            </div>
                                        </section>
                                        <section style={styles.serviceCard}>
                                            <div>
                                                {
                                                    groupPricesByWeightAndPrice(item.specialPrices[0].prices).map(userPrices => (
                                                        <section style={styles.priceItem}>
                                                            <Typography>{userPrices.weight}</Typography>
                                                            <Typography>{userPrices.price}</Typography>
                                                        </section>
                                                    ))
                                                }
                                            </div>
                                        </section>
                                    </article>

                                ))
                            }

                        </Row>
                    )
                }
            </Row>

        </SectionLayout>
    )
}

export default SpecialPricesPage